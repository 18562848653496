import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO, getWeek } from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from 'react-data-table-component';
import { AiFillEye } from 'react-icons/ai';
import {
  Badge,
  InputGroup,
  Form,
} from 'react-bootstrap';

import { fetchLatestCheckinForAllUsers } from 'services/checkin.service';

const CheckinsCheckup = () => {
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const calculateStatus = date => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const commsNeedded = date < oneWeekAgo;

    return commsNeedded ? <Badge bg="danger">NEEDED</Badge> : <Badge bg="success">FINE</Badge>;
  };

  const columns = [
    {
      name: 'Voornaam',
      selector: row => row.user.firstName,
      sortable: true,
    },
    {
      name: 'Achternaam',
      selector: row => row.user.lastName,
      sortable: true,
    },
    {
      name: 'Status',
      cell: row => (calculateStatus(parseISO(row.latestRecord.createdAt))),
      sortable: true,
    },
    {
      name: 'Datum',
      selector: row => format(parseISO(row.latestRecord.createdAt), 'dd/MM/yyyy HH:mm'),
      sortable: true,
    },
    {
      name: 'Week',
      selector: row => getWeek(new Date(row.latestRecord.createdAt)),
      sortable: true,
    },
    {
      cell: row => <AiFillEye onClick={() => navigate(`/checkins/${row.latestRecord._id}`)} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const onRowClick = row => {
    navigate(`/checkins/${row.latestRecord._id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const response = await fetchLatestCheckinForAllUsers();
      const data = await response.json();

      setFilteredRows(data);
      setRows(data);
      setPending(false);
    };

    fetchData().catch(console.error);
  }, []);

  const handleSearch = event => {
    const term = event.target.value;
    setFilteredRows(
      rows.filter(item => (`${item.user.firstName} ${item.user.lastName}`).toLowerCase().includes(term.toLowerCase())),
    );
  };

  return (
    <React.Fragment>
      <div style={{ clear: 'both' }}></div>
      <p className="mb-3 mt-3">Latest checkin for every client.</p>
      <InputGroup className="mb-3 mt-3">
        <InputGroup.Text id="basic-addon1"><i className="nc-icon nc-zoom-split"></i></InputGroup.Text>
        <Form.Control
          placeholder="Zoek op naam"
          aria-label="Zoek op naam"
          aria-describedby="basic-addon1"
          onChange={handleSearch}
        />
      </InputGroup>
      <DataTable
        columns={columns}
        data={filteredRows}
        pagination
        progressPending={pending}
        onRowClicked={e => onRowClick(e)}
        progressComponent={<Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
      />
    </React.Fragment>
  );
};

export default CheckinsCheckup;
