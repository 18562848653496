import {
  Card, Row, Col,
} from 'react-bootstrap';
import './CardSkeleton.scss';

const CardSkeleton = ({ amount = 3 }) => {
  const SingleCard = () => (
    <Card style={{
      width: '100%',
      minHeight: '247px',
      padding: '0',
      margin: '0',
    }}>
      <Card.Body>
        <Card.Title as="h4"></Card.Title>
      </Card.Body>
    </Card>
  );

  return (
    <div className="container loading-skeleton">
      <Row>
        {[...Array(amount).keys()].map((result, index) => <Col key={index}><SingleCard {...result} /></Col>)}
      </Row>
    </div>
  );
};

export default CardSkeleton;
