import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { getWeek } from 'date-fns';
import { HiDocumentCheck } from 'react-icons/hi2';

import DataCard from 'components/DataCard';
import { fetchCheckinsForCurrentUser } from 'services/checkin.service';
import Pagination from 'components/Pagination';

import './Checkins.scss';

const limit = 10;

const Checkins = () => {
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [checkins, setCheckins] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async page => {
    setPending(true);
    const response = await fetchCheckinsForCurrentUser({ page, limit });
    const data = await response.json();

    setTotalPages(data?.totalPages || 1);
    setCurrentPage(data?.currentPage || 1);
    setCheckins(data?.checkins || []);
    setPending(false);
  };

  useEffect(() => {
    fetchData(currentPage).catch(console.error);
  }, []);

  const handlePageChange = page => {
    fetchData(page);
  };

  return (
    <Container>
      <Row>
        <Col md="12">
          <div className="header-title">
            <h3 className="card-title m-b-0 mb-2"><b>Laatste Checkins</b></h3>
            <Button variant="success" className="mb-3 mt-3" onClick={() => navigate('/checkin')}>
            Checkin
              <HiDocumentCheck />
            </Button>
          </div>

          {pending && (
            <span className="spinner-border spinner-border-sm"></span>
          )}

          {checkins.length === 0 && <p>Er zijn nog geen nieuwe open checkins</p>}

          <div className="latest-checkins">
            {checkins.map((checkin, index) => (
              <DataCard checkin={checkin} key={index} headerTitle={`Week ${getWeek(new Date(checkin.createdAt))}`} link={`/checkins/${checkin._id}`} />
            ))}
          </div>

          {checkins.length !== 0 && <Pagination handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />}
        </Col>
      </Row>
    </Container>
  );
};

export default Checkins;
