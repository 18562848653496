import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { Image, Transformation } from 'cloudinary-react';

import { getCurrentUser } from 'services/auth.service';
import { uploadAvatar, updateUser } from 'services/user.service';
import AvatarImg from 'images/generic-avatar.svg';
import LarsImg from 'images/lars.png';

const Profile = () => {
  const user = getCurrentUser();

  const [email, setEmail] = useState(user.email);
  const [cellphone, setCellPhone] = useState(user.cellphone);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [gender, setGender] = useState(user.gender || 'male');
  const [avatar, setAvatar] = useState(user.avatar);

  useEffect(() => {
  }, []);

  const onDrop = useCallback(file => {
    console.log('file', file);
    // setUploadedImage(file);

    // Upload the image to the backend
    const formData = new FormData();

    if (Array.isArray(file)) {
      formData.append('file', file[0]);
    } else {
      formData.append('file', file);
    }

    uploadAvatar(formData).then(response => {
      console.log('Image uploaded successfully:', response.data);
      setAvatar(response?.data);
    });
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    const response = await updateUser(
      {
        firstName, lastName, cellphone, email, gender, avatar,
      },
    );
    setEmail(response.email);
    setCellPhone(response.cellphone);
    setFirstName(response.firstName);
    setLastName(response.lastName);
    setGender(response.gender);
    setAvatar(response.avatar);

    toast.success('Profiel successvol aangepast.');
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">{user.firstName} {user.lastName}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col className="px-1" md="4">
                      <Form.Label>Avatar</Form.Label>
                      {!avatar && <Image width="150" src={AvatarImg} />}
                      {avatar && (<Image
                        cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                        loading="lazy"
                        height="150"
                        width="150"
                        crop="fit"
                        publicId={`${avatar.public_id}`}>
                        <Transformation
                          width="400"
                          height="400"
                          crop="thumb"
                          gravity="face"
                          quality="auto:good"
                          fetchFormat="auto"
                        />
                      </Image>)}
                    </Col>
                    <Col className="px-1 mt-5" md="4">
                      <Form.Group controlId="avatar">
                        <Dropzone onDrop={onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                Sleep foto hier neer of klik om foto te selecteren
                                <br />
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1" md="4">
                      <Form.Group className="mb-4" controlId="firstName">
                        <label htmlFor="firstName">Voornaam</label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder=""
                          onChange={e => setFirstName(e.target.value)}
                          value={firstName}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="4">
                      <Form.Group className="mb-4" controlId="lastName">
                        <label htmlFor="lastName">Naam</label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          onChange={e => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1" md="4">
                      <Form.Group controlId="email">
                        <label htmlFor="email">E-mail</label>
                        <Form.Control
                          type="text"
                          onChange={e => setEmail(e.target.value)}
                          name="email"
                          placeholder=""
                          value={email}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group className="mb-4" controlId="phone">
                        <label htmlFor="phone">Telefoon</label>
                        <Form.Control
                          type="text"
                          name="phone"
                          onChange={e => setCellPhone(e.target.value)}
                          value={cellphone}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1" md="4">
                      <Form.Group controlId="gender">
                        <Form.Label>Geslacht</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => setGender(e.target.value)}
                          value={gender}
                        >
                          <option value={'male'}>Man</option>
                          <option value={'female'}>Vrouw</option>
                          <option value={'other'}>Andere</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1 mt-4" md="12" style={{
                      display: 'flex',
                      justifyContent: 'flexStart',
                      gap: '1rem',
                    }}>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="success">
                        Update Profile
                      </Button>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          {user.coach && (
            <Col md="4">
              <Card className="card-user">
                <Card.Body>
                  <div style={{ textAlign: 'center' }}>
                    <h3>Coach</h3>
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={user.coach?.avatar || LarsImg}></img>
                    <h5 className="title">{user.coach?.firstName} {user.coach?.lastName}</h5>
                    {/* <p className="description">Hoofdcoach</p> */}
                  </div>
                  <p className="description text-center">
                  </p>
                </Card.Body>
                <hr></hr>
                <div className="button-container mr-auto ml-auto">
                  <Button
                    className="btn-simple btn-icon"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    variant="link">
                    <i className="fab fa-facebook-square"></i>
                  </Button>
                  <Button
                    className="btn-simple btn-icon"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    variant="link">
                    <i className="fab fa-twitter"></i>
                  </Button>
                  <Button
                    className="btn-simple btn-icon"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    variant="link">
                    <i className="fab fa-google-plus-square"></i>
                  </Button>
                </div>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Profile;
