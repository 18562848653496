import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { getCurrentUser } from 'services/auth.service';

const ProtectedRoutes = ({
  roleRequired,
}) => {
  const user = getCurrentUser();

  // if the role required is there or not
  if (roleRequired) {
    return user ? (
      user.roles.includes(roleRequired) ? (
        <Outlet />
      ) : (
        <Navigate to="/denied" />
      )
    ) : (
      <Navigate to="/login" />
    );
  }

  return user ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoutes;
