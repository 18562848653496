import { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Row,
  Modal,
  Col,
} from 'react-bootstrap';
import {
  format, nextSaturday, nextSunday,
} from 'date-fns';
import { nlBE } from 'date-fns/locale';

import RadioButtonCard from 'components/RadioButtonCard';
import { fetchTeamTrainingSlots, subscribeTeamTraining } from 'services/team-training.service';
import { getCurrentUser } from 'services/auth.service';

const TeamTraining = () => {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const [timeslotChosen, setTimeslotChosen] = useState(null);
  const [timeslots, setTimeslots] = useState([]);
  const user = getCurrentUser();
  const isCoach = user?.roles.includes('ROLE_COACH');
  const userAlreadySubscribed = timeslots.some(timeslot => timeslot.subscribers.some(subscriber => subscriber.user._id === user.id));
  const timeSlotFoundForUser = timeslots.reduce((maxTimeslot, currentTimeslot) => {
    const matchingSubscribersCount = currentTimeslot.subscribers.filter(
      subscriber => subscriber.user._id === user.id,
    ).length;

    if (matchingSubscribersCount === 0) {
      return maxTimeslot; // No matching subscribers, keep the previous maxTimeslot
    }

    if (!maxTimeslot) {
      return currentTimeslot; // First valid timeslot found
    }

    const maxMatchingSubscribersCount = maxTimeslot.subscribers.filter(
      subscriber => subscriber.user._id === user.id,
    ).length;

    return matchingSubscribersCount > maxMatchingSubscribersCount ? currentTimeslot : maxTimeslot;
  }, null);

  const userAlreadySubscribedTimeslot = timeSlotFoundForUser || false;

  useEffect(() => {
    const fetchAllTeamTrainingSlots = async () => {
      const response = await fetchTeamTrainingSlots();
      const result = await response.json();

      setTimeslots(result);
    };

    fetchAllTeamTrainingSlots().catch(console.error);
  }, []);

  useEffect(() => {
    handleShowModal();
  }, [timeslotChosen]);

  const subscribeToTeamTraining = async () => {
    let date = null;
    if (timeslotChosen.weekDay === 'saturday') {
      date = format(nextSaturday(new Date()), 'yyyy-MM-dd');
    } else if (timeslotChosen.weekDay === 'sunday') {
      date = format(nextSunday(new Date()), 'yyyy-MM-dd');
    }

    await subscribeTeamTraining(timeslotChosen?._id, { date, teamtrainingId: timeslotChosen?._id });
    window.location.reload();
  };

  return (
    <Container fluid="true">
      <h1>Team training</h1>
      {(!userAlreadySubscribed && !isCoach) && <p>Schrijf je in voor één van de eerstvolgende teamtrainingen.</p>}
      {userAlreadySubscribed && (
        <p>Je bent ingeschreven voor tijdslot <b>{userAlreadySubscribedTimeslot.startTime}h - {userAlreadySubscribedTimeslot.endTime}h op {format(new Date(userAlreadySubscribedTimeslot.subscribers[0].date), 'EEEE d MMMM', { locale: nlBE })}</b>.</p>
      )}

      <Row>
        {timeslots.map((timeslot => (
          <Col key={`timeslot_${timeslot.dateTime}`} md="4" sm="12">
            <RadioButtonCard
              timeslot={timeslot}
              onClick={setTimeslotChosen}
              timeslotChosen={timeslotChosen}
              userAlreadySubscribed={userAlreadySubscribed}
              userAlreadySubscribedTimeslot={userAlreadySubscribedTimeslot}
              isCoach={isCoach}
            />
          </Col>
        )))}
      </Row>
      {timeslotChosen && (
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title><b>Inschrijven voor de Team training</b></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>
              <b>Wanneer</b>: {timeslotChosen?.weekDay === 'saturday' && format(nextSaturday(new Date()), 'EEEE d MMMM', { locale: nlBE })}
              {timeslotChosen?.weekDay === 'sunday' && format(nextSunday(new Date()), 'EEEE d MMMM', { locale: nlBE })}
            </p>
            <p><b>Tijdslot</b>: {timeslotChosen.startTime} - {timeslotChosen.endTime}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={subscribeToTeamTraining} variant="primary">Inschrijven</Button>
          </Modal.Footer>
        </Modal>
      )}
    </Container>
  );
};

export default TeamTraining;
