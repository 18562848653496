/* eslint-disable */
import { React, useState } from 'react';
import {
  useSearchParams,
  useNavigate
} from 'react-router-dom';
import {
  Button,
  Container,
  Carousel,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { resetPassword } from 'services/user.service';

import logo from 'images/logo-text.png';

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const userId = searchParams.get("id");
  const token = searchParams.get("token");
  const [newPassword, setNewPassword] = useState(null);
  const [affirmedNewPassword, setAffirmNewPassword] = useState(null);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== affirmedNewPassword)
      toast.error(`Beide wachtwoorden komen niet overeen.`, {
        autoClose: 5000,
        position: "top-right",
      });
    else {
      const res = await resetPassword({ newPassword, token, userId });

      if (!res.data.success) {
        toast.error(res.data.message, {
          autoClose: 5000,
          position: "top-right",
        });
      } else {
        toast.success(res.data.message, {
          autoClose: 5000,
          position: "top-right",
        });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      }
    }
  };

  return (
    <section className="login-block">
      <Container>
        <Row>
          <Col md="4" className="login-sec">
            <Row className="text-center">
              <img src={logo} alt="Logo" className="text-center" />
            </Row>
            <h3>Wachtwoord resetten</h3>

            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3 mt-4" controlId="newpassword">
                <label htmlFor="newpassword">Nieuw wachtwoord</label>
                <Form.Control
                  type="password"
                  name="newpassword"
                  autoFocus
                  onChange={e => setNewPassword(e.target.value)}
                  value={newPassword}
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="confirmpassword">
                <label htmlFor="confirmpassword">Bevestig nieuw wachtwoord</label>
                <Form.Control
                  type="password"
                  name="confirmpassword"
                  autoFocus
                  onChange={e => setAffirmNewPassword(e.target.value)}
                  value={affirmedNewPassword}
                />
              </Form.Group>
              <Button
                type="submit"
                fullWidth
                className="btn btn-login"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Wachtwoord resetten
              </Button>
              {message && (
                <div className="form-group mt-2">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </Form>
          </Col>
          <Col md="8" className="banner-sec">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/axjhwjg0n6tbax4wycbw"
                  alt="First slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/klsvmcirc7qruslkzf21"
                  alt="Second slide"
                />

                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/enndhhz82errxvtvpnap"
                  alt="Third slide"
                />

                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ResetPassword;