import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import { Col, Row } from 'react-bootstrap';
import { getWeek } from 'date-fns';
import MultiClamp from 'react-multi-clamp';

import { fetchAllFeedback } from 'services/feedback.service';
import Pagination from 'components/Pagination';

import Avatar from '../../../images/generic-avatar.svg';

const limit = 30;

const Feedback = () => {
  const navigate = useNavigate();
  const [feedback, setFeedback] = useState([]);
  const [pending, setPending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async page => {
    setPending(true);
    const response = await fetchAllFeedback({ page, limit });
    const data = await response.json();

    setTotalPages(data?.totalPages || 1);
    setCurrentPage(data?.currentPage || 1);
    setFeedback(data?.feedback || []);
    setPending(false);
  };

  useEffect(() => {
    fetchData(currentPage).catch(console.error);
  }, []);

  const handlePageChange = page => {
    fetchData(page);
  };

  return (
    <>
      {pending && <Spinner />}
      <Row lg={4}>
        {feedback.map((item, index) => (
          <Col className="d-flex" key={index} >
            <Card onClick={() => navigate(`/checkins/${item.checkin._id}`)} className="text-center" border={item.check ? 'success' : 'warning'} style={{ width: '18rem' }}>
              <div className="author">
                <img style={{ height: '100px', width: '100px' }} alt="..." className="avatar border-gray" src={Avatar} />
              </div>
              <Card.Body>
                <Card.Title>{item?.checkin?.user?.firstName} {item?.checkin?.user?.lastName} (week {getWeek(new Date(item?.checkin?.createdAt))})</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{item.subtitle}</Card.Subtitle>
                {item.questions && <Card.Text><b>HEB JE VRAGEN:</b> <br /> <MultiClamp ellipsis="..." clamp={3}>{item.questions}</MultiClamp></Card.Text>}
                {item.info && <Card.Text><b>HEB JE FEEDBACK:</b> <br /> <MultiClamp ellipsis="..." clamp={3}>{item.info}</MultiClamp> </Card.Text>}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      {feedback.length !== 0 && <Pagination handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />}
    </>
  );
};

export default Feedback;
