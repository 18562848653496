import React, { useEffect } from 'react';
import {
  Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { getCurrentUser, AuthVerify } from 'services/auth.service';

import ProtectedRoutes from 'ProtectedRoutes';
import OverviewCoach from 'containers/coach/Overview';
import OverviewClient from 'containers/client/Overview';
import clientRoutes from 'containers/client/routes';
import coachRoutes from 'containers/coach/routes';
import ForgotPassword from 'containers/forgotPassword';
import ResetPassword from 'containers/resetPassword';
import Login from 'containers/login';
import PageNotFound from 'containers/PageNotFound';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const getRoutes = routes => routes.map((prop, key) => (
  <Route
    path={prop.path}
    element={<prop.component />}
    key={key}
  />
));

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = getCurrentUser();

  useEffect(() => {
    if (user) {
      if (location.pathname === '/') {
        if (user.roles.includes('ROLE_COACH')) {
          navigate('/dashboard');
        }

        if (user.roles.includes('ROLE_CLIENT')) {
          navigate('/checkins');
        }
      }
    } else if (
      !location.pathname.includes('/login') &&
      (!location.pathname.includes('/forgot-password') && !location.pathname.includes('/reset-password'))
    ) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('fromUrl', location.pathname);
      navigate(`login?${searchParams.toString()}`);
    }
  }, [navigate]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<ProtectedRoutes />}>
          {user?.roles.includes('ROLE_CLIENT') &&
            <Route exact element={<ProtectedRoutes roleRequired="ROLE_CLIENT" />}>
              <Route exact path="" element={<OverviewClient />} >
                {getRoutes(clientRoutes)}
              </Route>
            </Route>
          }
          {user?.roles.includes('ROLE_COACH') &&
            <Route exact element={<ProtectedRoutes roleRequired="ROLE_COACH" />}>
              <Route exact path="" element={<OverviewCoach />} >
                {getRoutes(coachRoutes)}
              </Route>
            </Route>
          }
        </Route>
        <Route exact path="login" element={<Login />} />
        <Route path="forgot-password"
          element={<ForgotPassword />} />
        <Route path="reset-password"
          element={<ResetPassword />} />
        <Route
          path="*"
          element={<PageNotFound />}
        />
      </Routes>

      <AuthVerify />
    </React.Fragment>
  );
};

export default App;
