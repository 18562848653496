import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import LatestCheckins from './LatestCheckins';
import CheckinsCount from './CheckinsCount';
import ClientsCount from './ClientsCount';

const Dashboard = () => (
  <>
    <Container fluid="true">
      <Row>
        <Col lg="3" sm="6">
          <ClientsCount />
        </Col>
        <Col lg="3" sm="6">
          <CheckinsCount />
        </Col>
      </Row>
      <Row>
        <Col md="12" sm="12">
          <LatestCheckins />
        </Col>
      </Row>
    </Container>
  </>
);

export default Dashboard;
