import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const submitFeedback = (id, embed) => fetch(`${API_URL}checkins/${id}/feedback`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify({ embed }),
});

const submitClientFeedback = (id, body) => fetch(`${API_URL}checkins/${id}/feedback/client`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(body),
});

const fetchFeedback = id => fetch(`${API_URL}checkins/${id}/feedback`, { headers: authHeader() });

const fetchAllFeedback = query => fetch(`${API_URL}/feedback?page=${query.page}&limit=${query.limit}`, { headers: authHeader() });

export {
  submitFeedback,
  submitClientFeedback,
  fetchFeedback,
  fetchAllFeedback,
};
