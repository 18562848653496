import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Breadcrumbs = ({ breadcrumbs = [] }) => (
  <div className="container">
    <Breadcrumb>
      {breadcrumbs.map((breadcrumb, index) => <Breadcrumb.Item key={`breadcrumb_${index}`} href={breadcrumb.path} active={breadcrumb.active}>{breadcrumb.label}</Breadcrumb.Item>)}
    </Breadcrumb>
  </div>
);

export default Breadcrumbs;
