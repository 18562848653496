import React, { useState } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import {
  Button,
  Form,
  Carousel,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import validator from 'validator';

import { login, initialPasswordSet } from 'services/auth.service';
import { withRouter } from 'common/with-router';

import logo from 'images/logo-text.png';

import './Login.scss';

const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [email, setemail] = useState(searchParams.get('email') || '');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLogin = e => {
    e.preventDefault();
    setMessage('');
    setLoading(true);

    if (!validator.isEmail(email)) {
      setMessage('Gebruik een correct e-mailadres.');
      return;
    }

    login(email, password).then(
      user => {
        const { roles } = user;

        if (roles.includes('ROLE_COACH')) {
          navigate('/');
        }

        if (roles.includes('ROLE_CLIENT')) {
          const fromUrl = searchParams.get('fromUrl');

          if (fromUrl) {
            navigate(fromUrl);
          } else {
            navigate('/checkins');
          }
        } else {
          window.location.reload();
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setLoading(false);
        setMessage(resMessage);
      },
    );
  };

  const handlePasswords = e => {
    e.preventDefault();

    if (!validator.isStrongPassword(password, {
      minLength: 8,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
    })) {
      setMessage('Wachtwoord niet sterk genoeg.');
      return;
    }

    if (password !== passwordCheck) {
      setMessage('Beide wachtwoorden zijn het niet hetzelfde.');
    } else {
      initialPasswordSet(email, password).then(
        user => {
          const { roles } = user;

          if (roles.includes('ROLE_COACH')) {
            navigate('/');
          }

          if (roles.includes('ROLE_CLIENT')) {
            const fromUrl = searchParams.get('fromUrl');

            if (fromUrl) {
              navigate(fromUrl);
            } else {
              navigate('/checkins');
            }
          }
        },
        error => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        },
      );
    }
  };

  if (searchParams.get('email')) {
    return (
      <section className="login-block">
        <Container>
          <Row>
            <Col md="4" className="login-sec">
              <Row className="text-center">
                <img src={logo} alt="Logo" className="text-center" />
              </Row>
              <Form onSubmit={handlePasswords} className="login-form mt-5">
                <div className="form-group">
                  <label htmlFor="password">Wachtwoord</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="password">Bevestig wachtwoord</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={passwordCheck}
                    onChange={e => setPasswordCheck(e.target.value)}
                    required
                  />
                </div>

                {message && (
                  <div className="form-group mt-2">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}

                <div className="form-check">
                  <Button
                    type="submit"
                    disabled={loading}
                    className="btn btn-login mt-2"
                    style={{ float: 'right' }}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm"></span>
                    )}
                    <span>Login</span>
                  </Button>
                </div>

              </Form>
              <div className="copy-text">SuperHuman Coaching <i className="fa fa-heart"></i></div>
            </Col>
            <Col md="8" className="banner-sec">
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/axjhwjg0n6tbax4wycbw"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/klsvmcirc7qruslkzf21"
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/enndhhz82errxvtvpnap"
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return (
    <section className="login-block">
      <Container>
        <Row>
          <Col md="4" className="login-sec">
            <Row className="text-center">
              <img src={logo} alt="Logo" className="text-center" />
            </Row>
            <Form onSubmit={handleLogin} className="login-form mt-5">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={e => setemail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Wachtwoord</label>
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
              </div>

              {message && (
                <div className="form-group mt-2">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}

              <div className="form-check">
                <Button
                  type="submit"
                  disabled={loading}
                  className="btn btn-login mt-2"
                  style={{ float: 'right' }}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span>Login</span>
                </Button>

                <Link
                  to="/forgot-password"
                  className="btn-link mt-3 pe-3"
                  style={{ float: 'right' }}
                >
                  Wachtwoord vergeten
                </Link>
              </div>

            </Form>
            <div className="copy-text">SuperHuman Coaching <i className="fa fa-heart"></i></div>
          </Col>
          <Col md="8" className="banner-sec">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/axjhwjg0n6tbax4wycbw"
                  alt="First slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/klsvmcirc7qruslkzf21"
                  alt="Second slide"
                />

                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/enndhhz82errxvtvpnap"
                  alt="Third slide"
                />

                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withRouter(Login);
