import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RangeSlider from 'react-bootstrap-range-slider';
import {
  Badge,
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';
import PhotoAlbum from 'react-photo-album';
import Lightbox from 'yet-another-react-lightbox';
import { getWeek, parseISO, format } from 'date-fns';
import { FilePdfFill } from 'react-bootstrap-icons';
import { BsSendFill } from 'react-icons/bs';
import { IoLockClosed } from 'react-icons/io5';
import { FaImages } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { Image, Transformation } from 'cloudinary-react';

// eslint-disable-next-line
import { fetchCheckin, updateCheckinStatus, deleteCheckin } from 'services/checkin.service';
import {
  renderRating,
  renderHungryRating,
  renderRelaxedRating,
  renderEnergyLevel,
} from 'helpers/checkin';
import { submitFeedback, fetchFeedback, submitClientFeedback } from 'services/feedback.service';
import { submitTrainingScheme } from 'services/training-scheme.service';
import { getCurrentUser } from 'services/auth.service';
import { getStatus } from 'helpers/status';

import LarsImg from 'images/lars.png';

const DEFAULT_COACH_NAME = 'Lars De Munck';

const CheckinDetail = () => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const { id } = useParams();
  const [pending, setPending] = useState(false);
  const [checkin, setCheckin] = useState(undefined);
  const [feedback, setFeedback] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(-1);

  useEffect(() => {
    setPending(true);

    if (!loaded) {
      Promise.all([
        fetchFeedback(id),
        fetchCheckin(id),
      ])
        .then(([resFeedback, resCheckin]) => Promise.all(
          [resFeedback.json(), resCheckin.json()],
        ))
        .then(([dataFeedback, dataCheckin]) => {
          setFeedback(dataFeedback);
          setCheckin(dataCheckin);
          setPending(false);
          setLoaded(true);
        });
    }
  }, []);

  const onSubmitFeedback = async e => {
    e.preventDefault();
    const response = await submitFeedback(id, e.target.value);
    const result = await response.json();
    setFeedback(result);
  };

  const onSubmitTrainingScheme = async e => {
    e.preventDefault();
    const pdf = e.target.files[0];
    const response = await submitTrainingScheme(id, pdf);
    await response.json();
  };

  const onSubmitClientFeedback = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formDataObj = Object.fromEntries([...formData.entries()]);
    const response = await submitClientFeedback(id, formDataObj);
    const result = await response.json();
    setFeedback(result);
  };

  const downloadTrainingSchema = () => {
    window.open(
      checkin.trainingScheme.secure_url,
      '_blank',
    );
  };

  const deleteSingleCheckin = async () => {
    await deleteCheckin(id);

    toast.success(`Checkin van ${checkin.user.firstName} ${checkin.user.lastName} verwijderd.`);
    navigate(-1);
  };

  const downloadProgressPics = async () => {
    const images = checkin.images || [];

    // Create a container element to trigger downloads
    const container = document.createElement('div');
    container.style.display = 'none';
    document.body.appendChild(container);

    // Use map to create an array of Promises
    const downloadPromises = images.map(async (image, i) => {
      const response = await fetch(image.secure_url);
      const blob = await response.blob();

      // Set a dynamic filename based on the current index or image's public ID
      const filename = `picture_${i}.jpeg`;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;

      // Append the anchor to the container
      container.appendChild(a);

      // Trigger the download
      a.click();

      // Remove the anchor from the container
      container.removeChild(a);

      // Clean up the URL object
      window.URL.revokeObjectURL(url);
    });

    // Use Promise.all to wait for all the download Promises to complete
    await Promise.all(downloadPromises);

    // Remove the container element
    document.body.removeChild(container);
  };

  const onCloseCheckin = async () => {
    const result = await updateCheckinStatus(id, 'reviewed');
    await result.json();
    window.location.reload();
  };

  if (!checkin) {
    return <h1>Loading...</h1>;
  }

  return (
    <>
      <Container fluid="true">
        <Row>
          <Col md="8">
            <Card>
              <Card.Body>
                {pending && <Spinner />}
                <Card.Title as="h3">
                  <b>Checkin week {getWeek(new Date(checkin.createdAt))} van {checkin.user.firstName} {checkin.user.lastName}</b>
                  <div className="float-end">
                    <Badge bg={checkin.status === 'reviewed' ? 'success' : 'dark'}>{getStatus(checkin.status)}</Badge>
                  </div>
                  <br />
                </Card.Title>
                {format(parseISO(checkin.createdAt), 'dd/MM/yyyy HH:mm')}
                <Form className="checkin-form">
                  <Form.Group className="mb-4 pt-3" controlId="weight">
                    <label htmlFor="weight">GEWICHT VANDAAG</label>
                    <Form.Control
                      value={checkin.weight}
                      disabled
                      type="text"
                      placeholder="Gewicht"
                      name="weight"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="radio-with-other">
                    <label htmlFor="dietFollowed">VOEDING 100% GEVOLGD?</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="Yes"
                      checked={checkin.dietFollowed}
                      disabled
                      name="dietFollowed"
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      checked={!checkin.dietFollowed}
                      disabled
                      id="No"
                      name="dietFollowed"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="dietFollowedRemarks">
                    <label htmlFor="dietFollowedRemarks">Indien nee, waarom niet? Dan kunnen we samen kijken voor een oplossing!</label>
                    <Form.Control
                      type="textarea"
                      disabled
                      style={{ height: '100px' }}
                      name="dietFollowedRemarks"
                      placeholder="Jouw antwoord"
                      value={checkin.dietFollowedRemarks}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="amountOfWaterReached">
                    <label htmlFor="amountOfWaterReached">Geadviseerde hoeveelheid water inname gehaald?</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      disabled
                      checked={checkin.amountOfWaterReached}
                      id="amountOfWaterReached"
                      name="amountOfWaterReached"
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="amountOfWaterReached"
                      disabled
                      checked={!checkin.amountOfWaterReached}
                      name="amountOfWaterReached"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="hungerLevel">
                    <label htmlFor="hungerLevel">Honger gevoel <b>{renderHungryRating(checkin.hungerLevel)}</b></label>
                    <RangeSlider
                      min={1}
                      max={5}
                      variant="success"
                      name="hungerLevel"
                      tooltip="off"
                      disabled
                      value={checkin.hungerLevel}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="hungerLevelRemarks">
                    <label htmlFor="hungerLevelRemarks">Bij welke maaltijden heb je voordien, achteraf (veel) honger of juist absoluut geen honger? Hoe meer detail, hoe beter!</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="hungerLevelRemarks"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      value={checkin.hungerLevelRemarks}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="digestionRemarks">
                    <label htmlFor="digestionRemarks">Reguliere vertering en ontlasting (opgeblazen gevoel na bepaalde maaltijden, slechts 1/2x per week of 3x per dag ontlasten...)</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="digestionRemarks"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      value={checkin.digestionRemarks}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="bloodPressure">
                    <label htmlFor="bloodPressure">Bloeddruk op de ochtend van de check-in</label>
                    <Form.Control
                      type="text"
                      disabled
                      name="bloodPressure"
                      placeholder="Jouw antwoord"
                      value={checkin.bloodPressure}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="powerIncrease">
                    <label htmlFor="powerIncrease">Kracht toegenomen bij de trainingen? Zelfs één rep of 1.25kg extra is positief!</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="Yes"
                      disabled
                      checked={checkin.powerIncrease}
                      name="powerIncrease"
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="No"
                      disabled
                      checked={!checkin.powerIncrease}
                      name="powerIncrease"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="trainingFeeling">
                    <label htmlFor="trainingFeeling">Hoe zijn je trainingen verlopen? Haal je plezier uit de trainingen?</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="trainingFeeling"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      value={checkin.trainingFeeling}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="cardioFinished">
                    <label htmlFor="cardioFinished">Alle cardio sessies voltooid?</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="Yes"
                      disabled
                      checked={checkin.cardioFinished === 'yes'}
                      name="cardioFinished"
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="No"
                      disabled
                      checked={checkin.cardioFinished === 'no'}
                      name="cardioFinished"
                    />
                    <Form.Check
                      type="radio"
                      label="Niet van toepassing"
                      id="not-applicable"
                      disabled
                      checked={checkin.cardioFinished === 'not-applicable'}
                      name="cardioFinished"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="cardioFinishedRemarks">
                    <label htmlFor="cardioFinishedRemarks">Indien nee, waarom niet? Dan kunnen we samen kijken voor een oplossing!</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="cardioFinishedRemarks"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      value={checkin.cardioFinishedRemarks}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="stepsAmount">
                    <label htmlFor="stepsAmount">Dagelijkse hoeveelheid stappen gehaald</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="Yes"
                      disabled
                      checked={checkin.stepsAmount === 'yes'}
                      name="stepsAmount"
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="No"
                      disabled
                      checked={checkin.stepsAmount === 'no'}
                      name="stepsAmount"
                    />
                    <Form.Check
                      type="radio"
                      label="Niet van toepassing"
                      id="not-applicable"
                      disabled
                      checked={checkin.stepsAmount === 'not-applicable'}
                      name="stepsAmount"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="StepsAmountRemarks">
                    <label htmlFor="StepsAmountRemarks">Indien nee, waarom niet? Dan kunnen we samen kijken voor een oplossing!</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="StepsAmountRemarks"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      value={checkin.StepsAmountRemarks}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="sleepAmount">
                    <label htmlFor="sleepAmount">Hoeveel uur heb je gemiddeld geslapen per dag?</label>
                    <Form.Control
                      type="text"
                      disabled
                      name="sleepAmount"
                      placeholder="Jou antwoord"
                      value={checkin.sleepAmount}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="sleepQuality">
                    <label htmlFor="sleepQuality">Hoe was je slaap kwalitieit? <b>{renderRating(checkin.sleepQuality)}</b></label>
                    <RangeSlider
                      variant="success"
                      min={1}
                      max={5}
                      disabled
                      value={checkin.sleepQuality}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="energyLevel">
                    <label htmlFor="energyLevel">Hoe was je energie niveau deze week? <b>{renderEnergyLevel(checkin.energyLevel)}</b></label>
                    <RangeSlider
                      variant="success"
                      min={1}
                      max={5}
                      disabled
                      value={checkin.energyLevel}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="stressLevels">
                    <label htmlFor="stressLevels">Stress niveau deze week  <b>{renderRelaxedRating(checkin.stressLevels)}</b></label>
                    <RangeSlider
                      variant="success"
                      min={1}
                      max={5}
                      name="stressLevels"
                      disabled
                      value={checkin.stressLevels}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="stressLevelsRemarks">
                    <label htmlFor="stressLevelsRemarks">Verduidelijk in detail (slaap, stress en energie!)</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="stressLevelsRemarks"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      value={checkin.stressLevelsRemarks}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="progressMadeRemarks">
                    <label htmlFor="progressMadeRemarks">
                      Ben je blij met de progress dat je deze week hebt gemaakt? (wat is jouw WIN deze week, kracht in de gym, gedaan wat je moest doen... Het zijn wekelijks de kleine wins dat het verschil maken!)
                    </label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="progressMadeRemarks"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      value={checkin.progressMadeRemarks}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="coachFeedback">
                    <label htmlFor="coachFeedback">Feedback over de coaching:</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="coachFeedback"
                      placeholder="Jouw antwoord"
                      style={{ height: '150px' }}
                      value={checkin.coachFeedback}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="coachingExperience">
                    <label htmlFor="coachFeedback">Is er iets dat ik kan doen om jouw coaching ervaring nog beter te maken?:</label>
                    <Form.Control
                      as="textarea"
                      disabled
                      name="coachingExperience"
                      placeholder="Jouw antwoord"
                      style={{ height: '150px' }}
                      value={checkin.coachingExperience}
                    />
                  </Form.Group>

                  <div className="clearfix"></div>

                  <div className="banner-img" style={{ width: '100%' }}>
                    <PhotoAlbum
                      layout="rows"
                      targetRowHeight={150}
                      onClick={({ index }) => setGalleryIndex(index)} photos={(checkin.images || []).filter(image => image.secure_url).map(image => ({
                        src: image.secure_url,
                        width: image.width,
                        height: image.height,
                      }))} />
                    <Lightbox
                      slides={(checkin.images || []).filter(image => image.secure_url).map(image => ({
                        src: image.secure_url,
                        width: image.width,
                        height: image.height,
                      }))}
                      open={galleryIndex >= 0}
                      index={galleryIndex}
                      close={() => setGalleryIndex(-1)}
                      plugins={[]}
                    />
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user sticky-top">
              <Card.Body style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}>
                <div className="author">
                  {(feedback?.user?.avatar?.secure_url || user?.coach?.avatar?.secure_url) && (
                    <><Image
                      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                      loading="lazy"
                      height="150"
                      width="150"
                      crop="fit"
                      publicId={feedback?.user?.avatar?.public_id || user?.coach?.avatar?.public_id}>
                      <Transformation
                        width="400"
                        height="400"
                        crop="thumb"
                        gravity="face"
                        quality="auto:good"
                        radius="max"
                        fetchFormat="auto" />
                    </Image>
                    <h5 className="title"><b>{feedback?.user?.firstName || user?.coach?.firstName} {feedback?.user?.lastName || user?.coach?.lastName}</b></h5></>
                  )}
                  {(!feedback?.user?.avatar?.secure_url && !user?.coach?.avatar?.public_id) &&
                    <><img
                      alt="..."
                      className="avatar border-gray"
                      src={LarsImg} />
                      <h5 className="title"><b>{DEFAULT_COACH_NAME}</b></h5></>
                  }
                </div>
                {(checkin.status === 'open' && user.roles.includes('ROLE_CLIENT')) &&
                  <p><b>Nog geen feedback van de coach.</b></p>
                }
                {(checkin.status === 'open' && user.roles.includes('ROLE_COACH')) &&
                  <Form.Group className="mb-3" style={{ width: '100%' }} controlId="loomembed">
                    <Form.Label>Loom Embed</Form.Label>
                    <Form.Control as="textarea" style={{ height: '200px' }} defaultValue={feedback?.embed} rows={30} onChange={e => onSubmitFeedback(e)} />
                  </Form.Group>
                }

                {(checkin.status === 'open' && user.roles.includes('ROLE_COACH')) &&
                  <Form.Group className="mb-3" controlId="trainingscheme">
                    <Form.Label>Trainingschema</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".pdf"
                      label="Trainingsschema"
                      onChange={(e => onSubmitTrainingScheme(e))}
                    />
                  </Form.Group>
                }

                {checkin.status !== 'open' &&
                  <p className="description text-center">
                    <iframe title="feedback" srcDoc={feedback?.embed} />
                  </p>
                }

                {((checkin.status !== 'open' && checkin.trainingScheme) || (checkin.status === 'open' && user.roles.includes('ROLE_COACH') && checkin.trainingScheme)) && (
                  <Button variant="success" className="mb-3 mt-3" onClick={() => downloadTrainingSchema()}>
                    Trainingschema
                    <FilePdfFill />
                  </Button>
                )}

                {(user.roles.includes('ROLE_COACH')) && (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                    {(checkin.status === 'open') &&
                      <Form onSubmit={onCloseCheckin}>
                        <Button
                          className="pull-right"
                          disabled={!feedback?.embed}
                          type="submit"
                          variant="warning"
                        >
                          Afsluiten
                          <IoLockClosed />
                        </Button>
                      </Form>
                    }
                    <Button
                      variant="success"
                      className="mb-3 mt-3 pull-right"
                      onClick={() => downloadProgressPics()}
                    >
                      Download Progress Pics &nbsp;
                      <FaImages />
                    </Button>
                    <Button
                      variant="danger" className="mb-3" onClick={() => deleteSingleCheckin()}>
                      Verwijder Checkin &nbsp;
                      <MdDelete />
                    </Button>
                  </div>
                )}

                {(
                  // checkin.user.roles.includes('ROLE_CLIENT') &&
                  checkin.status === 'reviewed'
                ) && (
                    <Form
                      className="client-feedback-form"
                      style={{ width: '100%', textAlign: 'center' }}
                      onSubmit={onSubmitClientFeedback}
                    >
                      <hr></hr>
                      <h4 className="title"><b>Feedback {checkin.user.firstName}</b></h4>
                      <Form.Group className="mb-4" controlId="check">
                        <label htmlFor="check">Alles duidelijk van de check-in</label>
                        <Form.Check // prettier-ignore
                          style={{
                            scale: '1.7',
                            top: '1.2rem',
                            marginRight: '0.8rem',
                          }}
                          type={'checkbox'}
                          id={'check-checkbox'}
                          name="check"
                          disabled={feedback?.status === 'reviewed'}
                          checked={feedback?.check}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4" controlId="questions">
                        <label htmlFor="questions">Heb je vragen?</label>
                        <Form.Control
                          as="textarea"
                          name="questions"
                          disabled={feedback?.status === 'reviewed'}
                          placeholder="Jouw antwoord"
                          style={{ height: '100px' }}
                          value={feedback?.questions}
                        />
                      </Form.Group>

                      <Form.Group className="mb-4" controlId="info">
                        <label htmlFor="info">Heb je feedback?</label>
                        <Form.Control
                          as="textarea"
                          disabled={feedback?.status === 'reviewed'}
                          name="info"
                          placeholder="Jouw antwoord"
                          style={{ height: '100px' }}
                          value={feedback?.info}
                        />
                      </Form.Group>

                      {feedback?.status === 'open' &&
                        <Button
                          className="pull-right"
                          type="submit"
                          variant="success">
                          Verzenden
                          <BsSendFill />
                        </Button>
                      }
                    </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CheckinDetail;
