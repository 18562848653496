import {
  Accordion,
  Badge,
  Card,
} from 'react-bootstrap';
import classNames from 'classnames';
import { IoPeople } from 'react-icons/io5';
import { FaClock } from 'react-icons/fa6';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import {
  format, nextSaturday, nextSunday, isSaturday, isSunday,
} from 'date-fns';
import { nlBE } from 'date-fns/locale';
import Avatar from 'react-avatar';

import './RadioButtonCard.scss';

/* const statusMapper = {
  full: 'Volzet',
  open: 'Vrij',
}; */

const RadioButtonCard = ({
  timeslot,
  timeslotChosen,
  onClick,
  userAlreadySubscribed = false,
  userAlreadySubscribedTimeslot = {},
  isCoach = false,
}) => {
  const {
    totalSpots,
    weekDay,
    startTime,
    endTime,
    address,
    status,
    subscribers = [],
  } = timeslot;
  const clickCard = chosenTimeSlot => {
    if (userAlreadySubscribed || isCoach) return;
    onClick(chosenTimeSlot);
  };

  return (
    <a className="radio-button-card" onClick={() => clickCard(timeslot)}>
      <Card className={classNames('card-item', {
        active: (timeslotChosen?.startTime === startTime || startTime === userAlreadySubscribedTimeslot.startTime) && !isCoach,
        muted: (userAlreadySubscribedTimeslot?.startTime && startTime !== userAlreadySubscribedTimeslot.startTime) && !isCoach,
      })}>
        <div style={{ position: 'relative' }}>
          <Card.Img variant="top" src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/axjhwjg0n6tbax4wycbw" />
          <Badge style={{
            fontSize: '1.0rem',
            position: 'absolute',
            bottom: '1rem',
            right: '1rem',
          }} bg={status === 'full' ? 'danger' : 'success'}>Open</Badge>
          <Badge style={{
            fontSize: '1.0rem',
            position: 'absolute',
            bottom: '1rem',
            left: '1rem',
          }}><IoPeople size="1.0rem" /><strong>{subscribers.length}/{totalSpots || 15}</strong></Badge>
        </div>
        <Card.Body>
          <div className="top-info">
            <div className="left">
              <FaClock size="1.2rem" />
              <strong style={{ fontSize: '1.0rem', display: 'block' }}>
                {weekDay === 'saturday' && (
                  isSaturday(new Date()) ?
                    `Vandaag (${format(new Date(), 'd MMMM', { locale: nlBE })})` : // Format today's date if it's Saturday
                    format(nextSaturday(new Date()), 'EEEE d MMMM', { locale: nlBE }) // Otherwise, format next Saturday's date
                )}
                {weekDay === 'sunday' && (
                  isSunday(new Date()) ?
                    `Vandaag (${format(new Date(), 'd MMMM', { locale: nlBE })})` : // Format today's date if it's Sunday
                    format(nextSunday(new Date()), 'EEEE d MMMM', { locale: nlBE }) // Otherwise, format next Sunday's date
                )}
              </strong>
            </div>
            <div className="right">
              <MdDateRange size="1.2rem" />
              <strong style={{ fontSize: '1.0rem', display: 'block' }}>
                {startTime}h - {endTime}h
              </strong>
            </div>
          </div>
          <div className="stats">
            <div className="left">
              <FaMapMarkerAlt size="1.0rem" />
              <strong>
                {address.street} {address.houseNumber}
                , {address.postal} {address.city}
              </strong>
            </div>
          </div>
        </Card.Body>
        {isCoach && <Accordion>
          <Accordion.Item eventKey="0" flush>
            <Accordion.Header><b>Aanwezigen ({subscribers.length})</b></Accordion.Header>
            <Accordion.Body>
              {subscribers.map((subscriber => <span
                style={{ width: '100%', display: 'block', marginBottom: '1rem' }}
                key={`avatar_${subscriber.user.firstName}_${subscriber.user.lastName}`}
              ><Avatar
                  name={`${subscriber.user.firstName} ${subscriber.user.lastName}`}
                  alt={`${subscriber.user.firstName} ${subscriber.user.lastName}`}
                  unstyled={false}
                  round={true}
                  size="40"
                  style={{
                    marginRight: '0.5rem',
                  }}
                />
                {subscriber.user.firstName} {subscriber.user.lastName}
              </span>
              ))}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        }
      </Card>
    </a>
  );
};

export default RadioButtonCard;
