import axios from 'axios';

import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const uploadAvatar = formData => axios
  .post(`${API_URL}user/avatar`, formData, { headers: authHeader() });

const updateUser = async body => {
  try {
    const response = await axios.put(
      `${API_URL}user`,
      body, // Don't need to stringify the body, axios will handle it
      {
        headers: {
          ...authHeader(),
          'Content-Type': 'application/json',
        },
      },
    );

    // Assuming you are expecting JSON in the response
    const responseData = response.data;

    if (responseData.accessToken) {
      localStorage.setItem('user', JSON.stringify(responseData));
    }

    return responseData;
  } catch (error) {
    // Handle errors, log them, and optionally return a default value or rethrow
    console.error('Error updating user:', error);
    throw error; // Rethrow the error or return a default value if needed
  }
};

const forgotPassword = email => axios
  .post(`${API_URL}user/forgot-password`, { email });

const resetPassword = ({ newPassword, token, userId }) => axios
  .post(`${API_URL}user/reset-password`, { newPassword, token, userId });

export {
  uploadAvatar,
  updateUser,
  forgotPassword,
  resetPassword,
};
