import React, { useEffect, useState } from 'react';
import {
  Button,
  Breadcrumb,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { RiDragMove2Line } from 'react-icons/ri';
import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { fetchTrainingVideoCategories, updateTrainingVideo } from 'services/training-videos.service';

const generateMongoId = () => {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
  return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => Math.floor(Math.random() * 16).toString(16)).toLowerCase();
};

const TrainingVideoCategories = () => {
  const [categories, setCategories] = useState([]);

  const [draggingItem, setDraggingItem] = useState(null);
  const [newItemName, setNewItemName] = useState('');

  useEffect(() => {
    const fetchAllTrainingVideosCategories = async () => {
      const response = await fetchTrainingVideoCategories();
      const result = await response.json();

      setCategories(result);
    };

    fetchAllTrainingVideosCategories().catch(console.error);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    const response = await updateTrainingVideo(categories);
    await response.json();

    toast.success('Categorieën Bijgewerkt');
  };

  const handleDragStart = (e, item) => {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  };

  const handleDragEnd = () => {
    setDraggingItem(null);
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = (e, targetItem) => {
    if (!draggingItem) return;

    const currentIndex = categories.indexOf(draggingItem);
    const targetIndex = categories.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      const updatedCategories = [...categories];
      updatedCategories.splice(currentIndex, 1);
      updatedCategories.splice(targetIndex, 0, draggingItem);

      // Update order property for each category based on new order
      updatedCategories.forEach((category, index) => {
        category.order = index + 1; // Assuming order starts from 1
      });

      setCategories(updatedCategories);
    }
  };

  const handleNameChange = e => {
    setNewItemName(e.target.value);
  };

  const addNewCategory = () => {
    // Generate a unique ID for the new item
    const newItem = {
      _id: generateMongoId(),
      label: newItemName,
      name: newItemName,
      order: categories.length,
    };

    // Add the new item to the state
    setCategories([...categories, newItem]);
    setNewItemName('');
  };

  const deleteCategory = categoryId => {
    setCategories(categories.filter(category => category._id !== categoryId));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Breadcrumb className="mt-3">
              <Breadcrumb.Item href={'/education/training'}>
                Training
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Categorieën</Breadcrumb.Item>
            </Breadcrumb>
            <div className="sortable-list">
              {categories.map(category => (
                <div
                  key={category._id}
                  className=
                  {`item ${category === draggingItem ?
                    'dragging' : ''
                  }`}
                  draggable="true"
                  onDragStart={e => handleDragStart(e, category)}
                  onDragEnd={handleDragEnd}
                  onDragOver={handleDragOver}
                  onDrop={e => handleDrop(e, category)}
                >
                  <Card key={category.id} className="mb-4">
                    <Card.Body className="position-relative">
                      <div style={{ float: 'left' }}><Card.Title as="h4"><RiDragMove2Line size="1rem" /> {category.label}</Card.Title></div>
                      <div style={{ float: 'right' }}>
                        <Button variant="danger" bsSize="small" onClick={() => deleteCategory(category._id)}><FaTrash size="1rem" /></Button>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
            </div>
            <Button
              style={{ backgroundColor: '#99fc86' }}
              className="mt-3 btn-fill"
              bsStyle="primary"
              onClick={onSubmit}
              bsSize="small">Opslaan</Button>
          </Col>
          <Col md="4">
            <div className="mt-5">
              <Form.Label htmlFor="inputPassword5">Nieuwe categorie</Form.Label>
              <Form.Control
                type="text"
                placeholder="Naam"
                value={newItemName}
                onChange={handleNameChange}
              />
              <Button bsStyle="primary" style={{ backgroundColor: '#99fc86' }} className="mt-3 btn-fill" onClick={addNewCategory}>
                Nieuwe categorie toevoegen
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TrainingVideoCategories;
