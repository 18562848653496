/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWeek } from 'date-fns';
import RangeSlider from 'react-bootstrap-range-slider';
import { Image, Transformation } from 'cloudinary-react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { BsSendFill } from 'react-icons/bs';

import { postCheckin } from 'services/checkin.service';
import { getCurrentUser } from 'services/auth.service';

import ImageUpload from './ImageUpload';
import { renderRating, renderHungryRating, renderRelaxedRating, renderEnergyLevel } from 'helpers/checkin';
import LarsImg from 'images/lars.png';

import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './Checkin.scss';

const ERROR_REQUIRED_TEXT = 'Dit is een verplicht veld.';

const Checkin = () => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const initState = {
    weight: '',
  };
  const [initialValues, setInitialValues] = useState(initState);
  const [selectedImages, setSelectedImages] = useState([]);
  const [pending, setPending] = useState(false);

  const onSubmit = async (values) => {
    if (selectedImages.length === 0) {
      toast.warn('Gelieve minstens 1 progress foto op te laden.');
      return;
    }

    values.images = selectedImages;

    try {
      setPending(true);
      const checkin = await postCheckin(values);
      toast.success('Je checkin is successvol in ingediend. Je coach gaat deze zo snel mogelijk bekijken.');
      navigate(`/checkins/${checkin._id}`);
    } catch (e) {
      setPending(false);
      toast.error(`Er ging iets mis met volgende foutmelding: ${e}`);
    }
  };

  const onError = error => {
    console.log("ERROR:::", error);
    // toast.error(`Er ging iets mis met volgende foutmelding: ${error}`);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onSubmit',
    // reValidateMode: "onChange",
    defaultValues: initialValues
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setInitialValues(initialValues => ({
        ...initialValues,
        [name]: value
      }));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <>
      <Container fluid="true">
        <Row>
          <Col md="8">
            <Card>
              <Card.Body>
                <Card.Title as="h4">Checkin week {getWeek(new Date())}</Card.Title>
                <Form onSubmit={handleSubmit(onSubmit, onError)} className="checkin-form">
                  <Form.Group className="mb-4" controlId="weight">
                    <label>GEWICHT VANDAAG</label>
                    <Form.Control
                      type="text"
                      placeholder="Gewicht"
                      isInvalid={!!getValues('weight') && !/^\d*\.?\d*$|^\d*,?\d*$/.test(getValues('weight'))}
                      {...register("weight", { required: ERROR_REQUIRED_TEXT })}
                    />
                    {getValues('weight') && !/^\d*\.?\d*$|^\d*,?\d*$/.test(getValues('weight')) && (
                      <Form.Text className="text-danger">
                        Geef een correct gewicht in zoals 84,4
                      </Form.Text>
                    )}
                    {errors.weight && (
                      <Form.Text className="text-danger">
                        {errors.weight.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4">
                    <label>VOEDING 100% GEVOLGD?</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="dietFollowed-yes"
                      value={true}
                      name="dietFollowed"
                      {...register("dietFollowed", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="dietFollowed-no"
                      value={false}
                      name="dietFollowed"
                      {...register("dietFollowed", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    {errors.dietFollowed && (
                      <Form.Text className="text-danger">
                        {errors.dietFollowed.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="dietFollowedRemarks">
                    <label>Indien nee, waarom niet? Dan kunnen we samen kijken voor een oplossing!</label>
                    <Form.Control
                      type="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("dietFollowedRemarks")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="amountOfWaterReached">
                    <label>Geadviseerde hoeveelheid water inname gehaald?</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="amountOfWaterReached-yes"
                      value={true}
                      name="amountOfWaterReached"
                      {...register("amountOfWaterReached", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="amountOfWaterReached-no"
                      value={false}
                      name="amountOfWaterReached"
                      {...register("amountOfWaterReached", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="hungerLevel">
                    <label>Honger gevoel <b>{renderHungryRating(getValues(('hungerLevel')))}</b></label>
                    <Controller
                      name="hungerLevel"
                      control={control}
                      defaultValue={1}
                      rules={{ required: ERROR_REQUIRED_TEXT }}
                      render={(props) => {
                        return (
                          <RangeSlider
                            min={1}
                            max={5}
                            variant="success"
                            tooltip="auto"
                            tooltipLabel={() => renderHungryRating(props.field.value)}
                            tooltipPlacement="bottom"
                            value={props.field.value}
                            onChange={(_, value) => {
                              props.field.onChange(value);
                            }}
                          />
                        );
                      }} />
                    {errors.hungerLevel && (
                      <Form.Text className="text-danger">
                        {errors.hungerLevel.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="hungerLevelRemarks">
                    <label>Bij welke maaltijden heb je voordien, achteraf (veel) honger of juist absoluut geen honger? Hoe meer detail, hoe beter!</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("hungerLevelRemarks", { required: ERROR_REQUIRED_TEXT })}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="digestionRemarks">
                    <label>Reguliere vertering en ontlasting (opgeblazen gevoel na bepaalde maaltijden, slechts 1/2x per week of 3x per dag ontlasten...)</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("digestionRemarks")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="bloodPressure">
                    <label>Bloeddruk op de ochtend van de check-in</label>
                    <Form.Control
                      type="text"
                      placeholder="Jouw antwoord"
                      {...register("bloodPressure")}
                    />
                  </Form.Group>


                  <Form.Group className="mb-4" controlId="powerIncrease">
                    <label>Kracht toegenomen bij de trainingen? Zelfs één rep of 1.25kg extra is positief!</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="powerIncrease-yes"
                      value={true}
                      name="powerIncrease"
                      {...register("powerIncrease", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="powerIncrease-no"
                      value={false}
                      name="powerIncrease"
                      {...register("powerIncrease", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    {errors.powerIncrease && (
                      <Form.Text className="text-danger">
                        {errors.powerIncrease.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="trainingFeeling">
                    <label>Hoe zijn je trainingen verlopen? Haal je plezier uit de trainingen?</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("trainingFeeling", { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.trainingFeeling && (
                      <Form.Text className="text-danger">
                        {errors.trainingFeeling.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="cardioFinished">
                    <label>Alle cardio sessies voltooid?</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="cardioFinished-yes"
                      value={'yes'}
                      name="cardioFinished"
                      {...register("cardioFinished", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="cardioFinished-no"
                      value={'no'}
                      name="cardioFinished"
                      {...register("cardioFinished", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    <Form.Check
                      type="radio"
                      label="Niet van toepassing"
                      id="cardioFinished-not-applicable"
                      value={'not-applicable'}
                      name="cardioFinished"
                      {...register("cardioFinished", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    {errors.cardioFinished && (
                      <Form.Text className="text-danger">
                        {errors.cardioFinished.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="cardioFinishedRemarks">
                    <label>Indien nee, waarom niet? Dan kunnen we samen kijken voor een oplossing!</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("cardioFinishedRemarks")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="stepsAmount">
                    <label>Dagelijkse hoeveelheid stappen gehaald</label>
                    <Form.Check
                      type="radio"
                      label="Ja"
                      id="stepsAmount-yes"
                      value={'yes'}
                      name="stepsAmount"
                      {...register("stepsAmount", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    <Form.Check
                      type="radio"
                      label="Nee"
                      id="stepsAmount-no"
                      value={'no'}
                      name="stepsAmount"
                      {...register("stepsAmount", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    <Form.Check
                      type="radio"
                      label="Niet van toepassing"
                      id="stepsAmount-not-applicable"
                      value={'not-applicable'}
                      name="stepsAmount"
                      {...register("stepsAmount", {
                        required: ERROR_REQUIRED_TEXT
                      })}
                    />
                    {errors.stepsAmount && (
                      <Form.Text className="text-danger">
                        {errors.stepsAmount.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="StepsAmountRemarks">
                    <label>Indien nee, waarom niet? Dan kunnen we samen kijken voor een oplossing!</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("StepsAmountRemarks")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="sleepAmount">
                    <label>Hoeveel uur heb je gemiddeld geslapen per dag?</label>
                    <Form.Control
                      type="text"
                      placeholder="Gewicht"
                      {...register("sleepAmount", { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.sleepAmount && (
                      <Form.Text className="text-danger">
                        {errors.sleepAmount.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="sleepQuality">
                    <label>Hoe was je slaap kwalitieit? <b>{renderRating(getValues(('sleepQuality')))}</b></label>
                    <Controller
                      name="sleepQuality"
                      control={control}
                      defaultValue={1}
                      render={(props) => {
                        return (
                          <RangeSlider
                            min={1}
                            max={5}
                            tooltip="auto"
                            variant="success"
                            tooltipLabel={() => renderRating(props.field.value)}
                            tooltipPlacement="bottom"
                            value={props.field.value}
                            onChange={(_, value) => {
                              props.field.onChange(value);
                            }}
                          />
                        );
                      }} />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="energyLevel">
                    <label>Hoe was je energie niveau deze week? <b>{renderEnergyLevel(getValues(('energyLevel')))}</b></label>
                    <Controller
                      name="energyLevel"
                      control={control}
                      defaultValue={1}
                      render={(props) => {
                        return (
                          <RangeSlider
                            min={1}
                            max={5}
                            tooltip="auto"
                            variant="success"
                            tooltipLabel={() => renderEnergyLevel(props.field.value)}
                            tooltipPlacement="bottom"
                            value={props.field.value}
                            onChange={(_, value) => {
                              props.field.onChange(value);
                            }}
                          />
                        );
                      }} />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="stressLevels">
                    <label>Stress niveau deze week  <b>{renderRelaxedRating(getValues(('stressLevels')))}</b></label>
                    <Controller
                      name="stressLevels"
                      control={control}
                      defaultValue={1}
                      render={(props) => {
                        return (
                          <RangeSlider
                            min={1}
                            max={5}
                            variant="success"
                            tooltip="auto"
                            tooltipLabel={() => renderRelaxedRating(props.field.value)}
                            tooltipPlacement="bottom"
                            value={props.field.value}
                            onChange={(_, value) => {
                              props.field.onChange(value);
                            }}
                          />
                        );
                      }} />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="stressLevelsRemarks">
                    <label>Verduidelijk in detail (slaap, stress en energie!)</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("stressLevelsRemarks")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="progressMadeRemarks">
                    <label>Wat was jouw win deze week? (training, voeding, stappen, habits…)</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '100px' }}
                      {...register("progressMadeRemarks")}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="coachFeedback">
                    <label>Feedback over de coaching:</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '150px' }}
                      {...register("coachFeedback")}
                    />
                  </Form.Group>


                  <Form.Group className="mb-4" controlId="coachingExperience">
                    <label>is er iets dat ik kan doen om jouw coaching ervaring nog beter te maken?</label>
                    <Form.Control
                      as="textarea"
                      placeholder="Jouw antwoord"
                      style={{ height: '150px' }}
                      {...register("coachingExperience")}
                    />
                  </Form.Group>

                  <ImageUpload setSelectedImages={setSelectedImages} />

                  <Button
                    className="pull-right"
                    type="submit"
                    disabled={pending}
                    variant="success">
                    Verzenden
                    <BsSendFill />
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user sticky-top">
              <Card.Body>
                <div className="author">
                  {currentUser?.coach?.avatar?.secure_url && (
                    <Image
                      cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                      loading="lazy"
                      height="150"
                      width="150"
                      crop="fit"
                      publicId={currentUser?.coach?.avatar?.public_id}>
                      <Transformation
                        width="400"
                        height="400"
                        crop="thumb"
                        gravity="face"
                        quality="auto:good"
                        radius="max"
                        fetchFormat="auto"
                      />
                    </Image>
                  )}
                  {!currentUser?.coach?.avatar?.secure_url &&
                    <img
                      alt="..."
                      className="avatar border-gray"
                      src={LarsImg}></img>
                  }
                  <h5 className="title"><b>{currentUser.coach ? `${currentUser.coach.firstName} ${currentUser.coach.lastName}` : 'Lars De Munck'}</b></h5>
                </div>
                <p className="description text-center">
                  {currentUser?.coach?.email || 'lars@superhuman-coaching.com'} <br></br>
                  "Become Superhuman" <br></br>
                </p>
              </Card.Body>
              <hr></hr>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Checkin;
