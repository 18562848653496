import React, { useState, useEffect } from 'react';

import { fetchCheckinsByStatus } from 'services/checkin.service';
import DataCard from 'components/DataCard';
import Pagination from 'components/Pagination';

import './LatestCheckins.scss';

const limit = 20;

const LatestCheckins = () => {
  const [checkins, setCheckins] = useState([]);
  const [pending, setPending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchData = async page => {
    setPending(true);
    const response = await fetchCheckinsByStatus('open', { page, limit });
    const data = await response.json();

    setCheckins(data?.checkins || []);
    setTotalPages(data?.totalPages || 1);
    setCurrentPage(data?.currentPage || 1);
    setPending(false);
  };

  useEffect(() => {
    fetchData(currentPage).catch(console.error);
  }, []);

  const handlePageChange = page => {
    fetchData(page);
  };

  return (
    <React.Fragment>
      <div className="card-body">
        <h3 className="card-title m-b-0 mb-2"><b>Laatste open checkins</b></h3>
      </div>

      {pending && (
        <span className="spinner-border spinner-border-sm"></span>
      )}

      {checkins.length === 0 && <p>Er zijn nog geen nieuwe open checkins</p>}

      <div className="latest-checkins">
        {checkins.map((checkin, index) => (
          <DataCard checkin={checkin} key={index} headerTitle={`${checkin.user.firstName} ${checkin.user.lastName}`} link={`/checkins/${checkin._id}`} />
        ))}
      </div>

      {checkins.length !== 0 && <Pagination handlePageChange={handlePageChange} totalPages={totalPages} currentPage={currentPage} />}
    </React.Fragment>
  );
};

export default LatestCheckins;
