/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import {
  Button, Breadcrumb,
  Card, Col, Row,
} from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  Navigation, Pagination, Scrollbar, A11y,
} from 'swiper/modules';
import { useNavigate } from 'react-router-dom';

import { getCurrentUser } from 'services/auth.service';
import { fetchDietVideos, fetchDietVideoCategories } from 'services/diet-videos.service';
import CardSkeleton from 'components/Skeleton/CardSkeleton/CardSkeleton';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-cards';
import './Diet.scss';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';
/* eslint-disable no-useless-escape */
const VIMEO_EMBED_ID_REGEX = /vimeo\.com\/video\/(\d+)/i;

const Diet = () => {
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const [dietVideos, setDietVideos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllDietVideos = async () => {
      const response = await fetchDietVideos();
      const results = await response.json();

      const resultsWithThumbnails = await Promise.all(results.map(async item => {
        const match = item.embed.match(VIMEO_EMBED_ID_REGEX);
        const vimeoId = match && match[1] ? match[1] : '922876198';
        const thumbnail = await fetch(`${API_URL}Diet-videos/${vimeoId}/thumbnail`);
        if (!thumbnail.ok) {
          throw new Error(`Failed to fetch thumbnail for video ID: ${vimeoId}`);
        }
        const data = await thumbnail.json();

        item.url = data.sizes[3].link_with_play_button;
        return item;
      }));

      setLoading(false);
      setDietVideos(resultsWithThumbnails);
    };

    const fetchAllDietVideosCategories = async () => {
      const response = await fetchDietVideoCategories();
      const result = await response.json();

      setCategories(result);
    };

    fetchAllDietVideos().catch(console.error);
    fetchAllDietVideosCategories().catch(console.error);
  }, []);

  return (
    <div>
      <Breadcrumb className="mt-3">
        <Breadcrumb.Item href={'/education'}>
          Educatie
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Voeding</Breadcrumb.Item>
      </Breadcrumb>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>Voeding Video&apos;s</h2>
        {currentUser.roles.includes('ROLE_COACH') && (
          <div className="mt-2">
            <Button style={{ backgroundColor: '#99fc86', border: '1px solid light-grey' }} className="btn-fill" bsStyle="primary" bsSize="small" onClick={() => navigate('/education/diet/add')}>Voeding video toevoegen</Button>
            <Button style={{ backgroundColor: '#99fc86', border: '1px solid light-grey' }} className="ms-2 btn-fill" bsStyle="primary" bsSize="small" onClick={() => navigate('/education/diet/categories')}>Categorieën bewerken</Button>
          </div>
        )}
      </div>
      <Row style={{ clear: 'both' }}>
        <Col md="12">
          {categories.sort((a, b) => a.order - b.order).map((category, indexCard) => (
            <div key={indexCard} className="mb-2">
              <h3>{category.label}</h3>
              {(dietVideos.filter(video => video.category._id === category._id).length === 0 && !isLoading) && <p>Er zijn nog geen video&apos;s</p>}
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={4}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 2,
                  },
                  639: {
                    slidesPerView: 3,
                  },
                  865: {
                    slidesPerView: 4,
                  },
                }}
                navigation
                pagination={{
                  el: '.swiper-pagination',
                  clickable: true,
                  renderBullet: (index, className) => (<span className={className}>{index + 1}</span>),
                }}
                style={{
                  // '--swiper-pagination-bottom': '-10px',
                  '--swiper-pagination-color': '#99fc86',
                  '--swiper-theme-color': '#99fc86',
                }}
                className={`Diet-swiper-wrapper Diet-swiper-${indexCard}`}
              >
                {isLoading && [...Array(6).keys()].map((result, index) => (
                  <SwiperSlide key={index} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                    <CardSkeleton amount={1} />
                  </SwiperSlide>
                ))}
                {dietVideos.filter(video => video.category._id === category._id).map((item, index) => (
                  <SwiperSlide key={index} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}>
                    <span>
                      <Card style={{
                        width: '100%',
                        minHeight: '247px',
                        padding: '0',
                        margin: '0',
                      }}>
                        <a href={`/education/diet/${item.slug}`}><Card.Img variant="top" src={item.url} /></a>
                        <Card.Body>
                          <Card.Title as="h4">{item.title}</Card.Title>
                        </Card.Body>
                      </Card>
                    </span>
                  </SwiperSlide>
                ))}
                <div className="Diet-swiper-pagination'" />
              </Swiper>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default Diet;
