import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsSendFill } from 'react-icons/bs';

import { addIntake, fetchIntakeForUser } from 'services/intake.service';
import { getCurrentUser } from 'services/auth.service';

import './Intake.scss';

const ERROR_REQUIRED_TEXT = 'Dit is een verplicht veld.';

const Intake = () => {
  const { id } = useParams();

  const currentUser = getCurrentUser();
  const [initialValues, setInitialValues] = useState({});
  const [pending, setPending] = useState(false);
  const [hasExistingIntake, setHasExistingIntake] = useState(false);
  const [intake, setIntake] = useState(null);

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      setInitialValues(newValues => ({
        ...newValues,
        [name]: value,
      }));
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setPending(true);

    fetchIntakeForUser(id || currentUser.id)
      .then(response => {
        // Check if response status is 404
        if (response.status === 404) {
          throw new Error('Intake not found');
        } else {
          return response.json();
        }
      })
      .then(result => {
        // Handle the result
        setHasExistingIntake(true);
        reset(result);
        setIntake(result);
      })
      .catch(error => {
        // Handle the error
        console.error('Error fetching intake:', error.message);
        // You can set state or perform other actions based on the error
      })
      .finally(() => {
        setPending(false);
      });
  }, []);

  const onSubmit = async values => {
    try {
      setPending(true);
      const result = await addIntake(values);
      reset(result);
      setHasExistingIntake(true);
      toast.success('Je intake is successvol in ingediend. Je coach gaat deze zo snel mogelijk bekijken.');
    } catch (e) {
      setPending(false);
      toast.error(`Er ging iets mis met volgende foutmelding: ${e}`);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <Card.Title as="h3">Intake {intake?.user?.firstName} {intake?.user?.lastName}</Card.Title>
                <Form onSubmit={handleSubmit(onSubmit)} className="intake-form">
                  <h2 className="h3">Basisgegevens</h2>

                  <Form.Group className="mb-4" controlId="age">
                    <Form.Label>LEEFTIJD:</Form.Label>

                    <Form.Control
                      type="number"
                      disabled={hasExistingIntake}
                      {...register('age', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.age && (
                      <Form.Text className="text-danger">
                        {errors.age.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="weight">
                    <Form.Label>GEWICHT:</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={!!getValues('weight') && !/^\d*\.?\d*$|^\d*,?\d*$/.test(getValues('weight'))}
                      disabled={hasExistingIntake}
                      {...register('weight', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {getValues('weight') && !/^\d*\.?\d*$|^\d*,?\d*$/.test(getValues('weight')) && (
                      <Form.Text className="text-danger">
                        Geef een correct gewicht in zoals 84,4
                      </Form.Text>
                    )}
                    {errors.weight && (
                      <Form.Text className="text-danger">
                        {errors.weight.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="length">
                    <Form.Label>LENGTE(CM):</Form.Label>
                    <Form.Control
                      type="number"
                      disabled={hasExistingIntake}
                      {...register('length', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.length && (
                      <Form.Text className="text-danger">
                        {errors.length.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <h2 className="h3">Doelen</h2>
                  <Form.Group className="mb-4" controlId="goal">
                    <Form.Label>Doel (short term + long term):</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('goal', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.goal && (
                      <Form.Text className="text-danger">
                        {errors.goal.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="currentDiet">
                    <Form.Label>Huidige voeding ( + eventueel kcal inname +/-):</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('currentDiet', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.currentDiet && (
                      <Form.Text className="text-danger">
                        {errors.currentDiet.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="profession">
                    <Form.Label>Welke job doe je? Is dit sedentair, semi-actief of actief? (stappen per dag, zwaar werk, niet zwaar werk)</Form.Label>
                    <Form.Control
                      type="textarea"
                      disabled={hasExistingIntake}
                      style={{ height: '100px' }}
                      {...register('profession', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.profession && (
                      <Form.Text className="text-danger">
                        {errors.profession.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="dayLayout">
                    <Form.Label>Hoe ziet jouw dag eruit? Liefst zo gedetailleerd mogelijk, zo kan ik jouw voedingsschema perfect opstellen:</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('dayLayout', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.dayLayout && (
                      <Form.Text className="text-danger">
                        {errors.dayLayout.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="sports">
                    <Form.Label>Welke sport(en) beoefen je?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('sports', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.sports && (
                      <Form.Text className="text-danger">
                        {errors.sports.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="trainingDays">
                    <Form.Label>Hoeveel keer per week zou je kunnen trainen?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('trainingDays', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.trainingDays && (
                      <Form.Text className="text-danger">
                        {errors.trainingDays.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="dislikedFoods">
                    <Form.Label>Dingen die je graag eet en niet graag eet?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('dislikedFoods', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.dislikedFoods && (
                      <Form.Text className="text-danger">
                        {errors.dislikedFoods.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="injuries">
                    <Form.Label>Heb je een blessure of blessures gehad waar ik rekening mee moet houden?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('injuries', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.injuries && (
                      <Form.Text className="text-danger">
                        {errors.injuries.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="fysicalComplaints">
                    <Form.Label>Heb jij fysieke klachten?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('fysicalComplaints', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.fysicalComplaints && (
                      <Form.Text className="text-danger">
                        {errors.fysicalComplaints.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="likedExercises">
                    <Form.Label>Oefeningen die jij goed voelt en leuk vindt (voor elke spiergroep):</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('likedExercises', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.likedExercises && (
                      <Form.Text className="text-danger">
                        {errors.likedExercises.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="dislikedExercises">
                    <Form.Label>Oefeningen die jij niet goed voelt en niet leuk vindt?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('dislikedExercises', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.dislikedExercises && (
                      <Form.Text className="text-danger">
                        {errors.dislikedExercises.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="availableMachines">
                    <Form.Label>Een lijst met al de machines die je ter beschikking hebt in jouw gym… (hack squat, v-qsuat… ALLES!) Dan weet ik welke oefeningen ik in je program kan zetten!</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('availableMachines', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.availableMachines && (
                      <Form.Text className="text-danger">
                        {errors.availableMachines.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="pedsHistory">
                    <Form.Label>Heb je in het verleden gebruik gemaakt van PEDs? Zoja, welke en voor hoelang?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('pedsHistory', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.pedsHistory && (
                      <Form.Text className="text-danger">
                        {errors.pedsHistory.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <h2 className="h3">Bodybuilding Show</h2>
                  <Form.Group className="mb-4" controlId="bodybuildshowHistory">
                    <Form.Label>Heb je al eens een show meegedaan?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('bodybuildshowHistory', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.bodybuildshowHistory && (
                      <Form.Text className="text-danger">
                        {errors.bodybuildshowHistory.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="bodybuildshowInterests">
                    <Form.Label>Zou je dit in de toekomst (nog eens) willen doen?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('bodybuildshowInterests', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.bodybuildshowInterests && (
                      <Form.Text className="text-danger">
                        {errors.bodybuildshowInterests.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="bodybuildshowCategory">
                    <Form.Label>Zoja, in welke categorie?</Form.Label>
                    <Form.Control
                      type="text"
                      disabled={hasExistingIntake}
                      {...register('bodybuildshowCategory', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.bodybuildshowCategory && (
                      <Form.Text className="text-danger">
                        {errors.bodybuildshowCategory.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="vacationsPlanned">
                    <Form.Label>Zijn er momenteel al vakantie periodes gepland waar ik rekening mee moet houden?</Form.Label>
                    <Form.Control
                      type="textarea"
                      style={{ height: '100px' }}
                      disabled={hasExistingIntake}
                      {...register('vacationsPlanned', { required: ERROR_REQUIRED_TEXT })}
                    />
                    {errors.vacationsPlanned && (
                      <Form.Text className="text-danger">
                        {errors.vacationsPlanned.message}
                      </Form.Text>
                    )}
                  </Form.Group>

                  {(!pending && !hasExistingIntake) && <Button
                    className="pull-right"
                    type="submit"
                    variant="success">
                    Indienen
                    <BsSendFill />
                  </Button>
                  }
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Intake;
