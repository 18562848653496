/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { addClient } from 'services/client.service';
import { fetchCoaches } from 'services/coaches.service';

const NewClient = () => {
  const navigate = useNavigate();
  const [coaches, setCoaches] = useState([]);

  const [email, setEmail] = useState('');
  const [cellphone, setCellPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [paymentRegularity, setPaymentRegularity] = useState('monthly');
  const [coach, setCoach] = useState('');
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const fetchCoachesData = async () => {
      const response = await fetchCoaches();
      const data = await response.json();

      setCoaches(data.map(singleCoach => ({
        value: singleCoach._id,
        label: `${singleCoach.firstName} ${singleCoach.lastName}`,
      })));
    };

    fetchCoachesData().catch(console.error);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setPending(true);
    const response = await addClient({ firstName, lastName, cellphone, email, paymentRegularity, coach });
    const client = await response.json();

    toast.success(`${client.firstName} ${client.lastName} saved to clients.`);
    navigate('/clients');
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
            <Card.Body>
             <Card.Title as="h4">Nieuwe Client</Card.Title>
              <Form onSubmit={onSubmit}>
                <Row>
                  <Col className="pl-1" md="4">
                    <Form.Group>
                      <label htmlFor="exampleInputEmail1">
                        Email
                      </label>
                      <Form.Control
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        type="email"></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <label>GSM-nummer</label>
                      <Form.Control
                        onChange={(e) => setCellPhone(e.target.value)}
                        placeholder="GSM-nummer"
                        type="text"></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="pr-1" md="6">
                    <Form.Group>
                      <label>Voornaam</label>
                      <Form.Control
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="Voornaam"
                        type="text"></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="6">
                    <Form.Group>
                      <label>Achternaam</label>
                      <Form.Control
                        onChange={(e) => setLastName(e.target.value)}
                        placeholder="Achternaam"
                        type="text"></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className="pr-1" md="6">
                    <Form.Group controlId="client">
                      <Form.Label>Betaling</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={(e) => setPaymentRegularity(e.target.value)}
                      >
                        <option value={'monthly'}>Maandelijks</option>
                        <option value={'quarterly'}>Kwartaal</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="px-1" md="4">
                      <Form.Group controlId="paymentRegularity">
                        <Form.Label>Coach</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => setCoach(e.target.value)}
                          value={coach}
                        >
                          <option value={''} disabled>Kies een coach</option>
                          {coaches.map(singleCoach => (
                            <option key={`coach_${singleCoach.value}`} value={singleCoach.value}>{singleCoach.label}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                </Row>
                <Button
                  style={{ backgroundColor: '#99fc86', border: '1px solid light-grey' }}
                  className="mt-3 btn-fill"
                  disabled={pending}
                  bsStyle="primary"
                  bsSize="small"
                  type="submit">Toevoegen</Button>
                <div className="clearfix"></div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default NewClient;
