import { format, parseISO, getWeek } from 'date-fns';
import Badge from 'react-bootstrap/Badge';
import Carousel from 'react-bootstrap/Carousel';
import { Image, Transformation } from 'cloudinary-react';

import {
  renderRating,
  renderHungryRating,
  renderRelaxedRating,
  renderEnergyLevel,
} from 'helpers/checkin';
import { getStatus } from 'helpers/status';

import './DataCard.scss';

const DataCard = ({
  checkin,
  // headerTitle,
  link,
}) => (
  <div className="data-card">
    <a href={link} style={{ textDecoration: 'none', color: 'black' }}>
      <div className="header"><b>week {getWeek(new Date(checkin.createdAt))}</b></div>

      <div className="banner-img" style={{ width: '100%', height: '100px' }}>
        <Carousel>
          {(checkin.images || []).map((image, index) => (
            <Carousel.Item key={index}>
              <Image
                cloudName={process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}
                loading="lazy"
                height="250"
                width="400"
                crop="scale"
                publicId={`${image.public_id}`}>
                <Transformation height="300" width="400" crop="fill" gravity="auto" quality="auto:good" fetchFormat="auto" />
              </Image>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

      <div className="client" style={{ textAlign: 'center' }}>
        <b>{checkin.user.firstName} {checkin.user.lastName}</b>
      </div>

      <div className="top-info">
        <div className="left">
          <strong>Ingecheckt om</strong> <span style={{ color: 'black' }}>{format(parseISO(checkin.createdAt), 'dd/MM/yyyy HH:mm')}</span>
        </div>
        <div className="right">
          <span style={{ fontSize: '1.5rem' }}><Badge bg={checkin.status === 'reviewed' ? 'success' : 'dark'}>{getStatus(checkin.status)}</Badge></span>
        </div>
      </div>

      <div className="stats">

        <div>
          <strong>GEWICHT</strong> {checkin.weight}
        </div>

        <div>
          <strong>DIEET GEVOLGD</strong> {checkin.dietFollowed ? 'Ja' : 'Nee'}
        </div>

        <div>
          <strong>HONGER</strong> {renderHungryRating(checkin.hungerLevel)}
        </div>

      </div>

      <div className="stats">

        <div>
          <strong>SLAAPKWALITEIT</strong> {renderRating(checkin.sleepQuality)}
        </div>

        <div>
          <strong>STRESSNIVEAU</strong> {renderRelaxedRating(checkin.stressLevels)}
        </div>

        <div>
          <strong>ENERGIELEVEL</strong> {renderEnergyLevel(checkin.energyLevel) || '-'}
        </div>

      </div>
    </a>
  </div>
);

export default DataCard;
