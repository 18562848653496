import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const postCommunication = values => fetch(`${API_URL}communication`, {
  headers: authHeader(),
  method: 'post',
  body: JSON.stringify(values),
});

const fetchCommunication = () => fetch(`${API_URL}communication`, {
  headers: authHeader(),
});

const fetchSingleCommunication = id => fetch(`${API_URL}communication/${id}`, {
  headers: authHeader(),
});

export {
  postCommunication,
  fetchCommunication,
  fetchSingleCommunication,
};
