import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const fetchCoaches = () => fetch(`${API_URL}coaches`, { headers: authHeader() });

const fetchCoach = id => fetch(`${API_URL}coaches/${id}`, { headers: authHeader() });

const deleteCoach = id => fetch(`${API_URL}coaches/${id}`, { method: 'DELETE', headers: authHeader() });

const updateCoach = (id, data) => fetch(`${API_URL}coaches/${id}`, { method: 'PUT', headers: authHeader(), body: JSON.stringify(data) });

const addCoach = client => fetch(`${API_URL}coaches`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(client),
});

export {
  addCoach,
  deleteCoach,
  updateCoach,
  fetchCoaches,
  fetchCoach,
};
