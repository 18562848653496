import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from 'react-data-table-component';
import {
  Badge,
  InputGroup,
  Form,
} from 'react-bootstrap';

import { fetchAllCheckins } from 'services/checkin.service';

const Checkins = () => {
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const calculateStatus = status => (status === 'open' ? <Badge bg="danger">Open</Badge> : <Badge bg="success">Reviewed</Badge>);

  const columns = [
    {
      name: 'ID',
      selector: row => row?.user?._id,
      sortable: true,
    },
    {
      name: 'Achternaam',
      selector: row => row?.user?.lastName,
      sortable: true,
    },
    {
      name: 'Voornaam',
      selector: row => row?.user?.firstName,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row?.user?.email,
      sortable: true,
    },
    {
      name: 'Status',
      cell: row => calculateStatus(row.status),
      sortable: true,
    },
    {
      name: 'Datum',
      selector: row => format(parseISO(row.createdAt), 'dd/MM/yyyy HH:mm'),
      sortable: true,
    },
  ];

  const onRowClick = row => {
    navigate(`/checkins/${row._id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const response = await fetchAllCheckins();
      const data = await response.json();
      const checkins = data?.checkins || [];

      setFilteredRows(checkins);
      setRows(checkins);
      setPending(false);
    };

    fetchData().catch(console.error);
  }, []);

  const handleSearch = event => {
    const term = event.target.value;

    setFilteredRows(
      rows.filter(item => {
        const { user } = item;
        return (
          user &&
          (`${user.firstName} ${user.lastName}`.toLowerCase().includes(term.toLowerCase()))
        );
      }),
    );
  };

  return (
    <React.Fragment>
      <h3 style={{ float: 'left' }}>Checkins</h3>
      <InputGroup className="mb-3 mt-3">
        <InputGroup.Text id="basic-addon1"><i className="nc-icon nc-zoom-split"></i></InputGroup.Text>
        <Form.Control
          placeholder="Zoek op naam"
          aria-label="Zoek op naam"
          aria-describedby="basic-addon1"
          onChange={handleSearch}
        />
      </InputGroup>
      <DataTable
        columns={columns}
        data={filteredRows}
        pagination
        progressPending={pending}
        onRowClicked={e => onRowClick(e)}
        progressComponent={<Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
      />
    </React.Fragment>
  );
};

export default Checkins;
