// eslint-disable-next-line
import CheckinDetail from 'components/CheckinDetail';
import Education from 'components/Education';
import Diet from 'components/Education/Diet';
import AddDietVideo from 'components/Education/Diet/Add';
import DietVideoCategories from 'components/Education/Diet/Categories';
import DietVideoDetail from 'components/Education/Diet/Detail';
import EditDietVideo from 'components/Education/Diet/Edit';
import Training from 'components/Education/Training';
import TrainingDetail from 'components/Education/Training/Detail';
import AddTrainingVideo from 'components/Education/Training/Add';
import EditTrainingVideo from 'components/Education/Training/Edit';
import TrainingVideoCategories from 'components/Education/Training/Categories';

import Intake from 'components/Intake';
import Dashboard from './Dashboard/Dashboard';
import Clients from './Clients/Clients';
import ClientDetail from './ClientDetail';
import NewClient from './NewClient';
import Checkins from './Checkins';
import Feedback from './Feedback';
import Checkup from './Checkup/Checkup';
import CommunicationDetail from './Communication/CommunicationDetail';
import Profile from './Profile';
import TeamTraining from '../mutual/TeamTraining';

const dashboardRoutes = [
  {
    path: 'dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    inNavbar: true,
    layout: '/dashboard',
  },
  {
    path: 'clients',
    name: 'Clients',
    icon: 'nc-icon nc-chart-pie-35',
    component: Clients,
    inNavbar: true,
    layout: '/dashboard',
  },
  {
    path: 'clients/:id',
    name: 'Client Detail',
    icon: 'nc-icon nc-chart-pie-35',
    component: ClientDetail,
    inNavbar: false,
    layout: '/dashboard',
  },
  {
    path: 'clients/add',
    name: 'New Client',
    icon: 'nc-icon nc-chart-pie-35',
    component: NewClient,
    inNavbar: false,
    layout: '/dashboard',
  },
  {
    path: 'checkins',
    name: 'Checkins',
    icon: 'nc-icon nc-chart-pie-35',
    component: Checkins,
    inNavbar: true,
    layout: '/dashboard',
  },
  {
    path: 'feedback',
    name: 'Feedback',
    icon: 'nc-icon nc-chart-pie-35',
    component: Feedback,
    inNavbar: true,
    layout: '/dashboard',
  },
  {
    path: 'checkup',
    name: 'Checkups',
    icon: 'nc-icon nc-chart-pie-35',
    component: Checkup,
    inNavbar: true,
    layout: '/dashboard',
  },
  {
    path: 'communication/:id',
    name: 'Communication Detail',
    icon: 'nc-icon nc-single-copy-04',
    component: CommunicationDetail,
    breadcrumb: 'detail',
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'checkins/:id',
    name: 'Checkin Detail',
    icon: 'nc-icon nc-single-copy-04',
    component: CheckinDetail,
    breadcrumb: 'detail',
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education',
    name: 'Educatie',
    icon: 'nc-icon nc-single-copy-04',
    component: Education,
    inNavbar: true,
    layout: '/admin',
  },
  {
    path: 'education/diet',
    name: 'Diet',
    icon: 'nc-icon nc-single-copy-04',
    component: Diet,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/diet/:slug',
    name: 'Voeding video Detail',
    icon: 'nc-icon nc-single-copy-04',
    component: DietVideoDetail,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/diet/:slug/edit',
    name: 'Diet Edit',
    icon: 'nc-icon nc-single-copy-04',
    component: EditDietVideo,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/diet/add',
    name: 'Add Dietvideo',
    icon: 'nc-icon nc-single-copy-04',
    component: AddDietVideo,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/diet/categories',
    name: 'Diet videos categories',
    icon: 'nc-icon nc-single-copy-04',
    component: DietVideoCategories,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/training',
    name: 'Training',
    icon: 'nc-icon nc-single-copy-04',
    component: Training,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/training/:slug',
    name: 'Training Detail',
    icon: 'nc-icon nc-single-copy-04',
    component: TrainingDetail,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/training/:slug/edit',
    name: 'Training Edit',
    icon: 'nc-icon nc-single-copy-04',
    component: EditTrainingVideo,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/training/add',
    name: 'Add Trainingvideo',
    icon: 'nc-icon nc-single-copy-04',
    component: AddTrainingVideo,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/training/categories',
    name: 'Edit Trainingvideos categories',
    icon: 'nc-icon nc-single-copy-04',
    component: TrainingVideoCategories,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'intake/:id',
    name: 'Intake',
    component: Intake,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'team-training',
    name: 'Team training',
    component: TeamTraining,
    inNavbar: true,
    layout: '/admin',
  },
  {
    path: 'profile',
    name: 'Profiel',
    icon: 'nc-icon nc-single-copy-04',
    component: Profile,
    inNavbar: true,
    layout: '/admin',
  },
];

export default dashboardRoutes;
