import {
  Container,
} from 'react-bootstrap';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Footer from 'components/Footer/Footer';

const PageNotFound = () => (
  <>
    <div className="wrapper">
      <AdminNavbar routes={[]} />
      <div className="main">
        <Container className="pt-3">
          <div className="content">
            <h3>Excuses.</h3>
            <p>Pagina kon niet gevonden worden.</p>
          </div>
          <Footer />
        </Container>
      </div>
    </div>
  </>
);

export default PageNotFound;
