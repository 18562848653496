import axios from 'axios';

import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/auth/' : 'http://localhost:2000/auth/';

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

const login = (email, password) => axios
  .post(`${API_URL}signin`, {
    email,
    password,
  })
  .then(response => {
    if (response.data.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }

    return response.data;
  });

const logout = () => {
  localStorage.removeItem('user');
  window.location.href = '/login';
};

const register = (username, email, password) => axios.post(`${API_URL}signup`, {
  username,
  email,
  password,
});

const initialPasswordSet = (email, password) => axios
  .post(`${API_URL}passwordset`, {
    email,
    password,
  }, { headers: { ...authHeader(), 'Content-Type': 'application/json' } })
  .then(response => {
    if (response.data.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }

    return response.data;
  });

const getCurrentUser = () => JSON.parse(localStorage.getItem('user'));

const AuthVerify = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user) {
    const decodedJwt = parseJwt(user.accessToken);

    if (decodedJwt.exp * 1000 < Date.now()) {
      logout();
    }
  }
};

export {
  login,
  logout,
  register,
  initialPasswordSet,
  getCurrentUser,
  AuthVerify,
};
