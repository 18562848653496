// Handle HTTP errors since fetch won't.
/* eslint-disable */
export default function handleErrors(response) {
  if (!response.ok) {
    return response.json().then(({ status, message}) => {
      throw {
        status: parseInt(status, 10),
        message,
      };
    });
  }
  return response;
}
