import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import DataTable, { createTheme } from 'react-data-table-component';
import { AiFillEye } from 'react-icons/ai';
import {
  Badge,
  Button,
  InputGroup,
  Form,
  Spinner,
} from 'react-bootstrap';

import { fetchCommunication } from 'services/communication.service';
import AddCommunication from './AddCommunication';

createTheme('test', {
  text: {
    'font-size': '15px',
    secondary: '#99fc86',
    primary: '#000000',
  },
  background: {
    default: 'transparent',
  },
  context: {
    background: '#cb4b16',
    text: '#99fc86',
  },
  divider: {
    default: '#99fc86',
  },
  action: {
    button: 'rgba(0,0,0,.54)',
    hover: 'rgba(0,0,0,.08)',
    disabled: 'rgba(0,0,0,.12)',
  },
  rows: {
    style: {
      fontSize: '25px',
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
      fontSize: '80px',
    },
  },
  header: {
    style: {
      fontSize: '80px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
  pagination: {
    style: {
      color: '#000000',
      fontSize: '13px',
      minHeight: '56px',
      backgroundColor: '#000000',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: '#000000',
    },
    pageButtonsStyle: {
      borderRadius: '50%',
      height: '40px',
      width: '40px',
      padding: '8px',
      margin: 'px',
      cursor: 'pointer',
      transition: '0.4s',
      color: '#000000',
      fill: '#000000',
      backgroundColor: 'transparent',
    },
  },
});

const Communication = () => {
  const navigate = useNavigate();
  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [show, setShow] = useState(false);

  const fetchData = async () => {
    setPending(true);
    const response = await fetchCommunication();
    const data = await response.json();

    setFilteredRows(data);
    setRows(data);
    setPending(false);
  };

  const handleClose = refresh => {
    setShow(false);
    if (refresh) {
      fetchData().catch(console.error);
    }
  };

  const handleShow = () => setShow(true);

  const calculateStatus = date => {
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

    const commsNeedded = date < oneWeekAgo;

    return commsNeedded ? <Badge bg="danger">NEEDED</Badge> : <Badge bg="success">FINE</Badge>;
  };

  const columns = [
    {
      name: 'Voornaam',
      selector: row => row.user.firstName,
      sortable: true,
    },
    {
      name: 'Achternaam',
      selector: row => row.user.lastName,
      sortable: true,
    },
    {
      name: 'Subject',
      selector: row => row.latestRecord.name,
      sortable: true,
    },
    {
      name: 'Status',
      cell: row => (calculateStatus(parseISO(row.latestRecord.createdAt))),
      sortable: true,
    },
    {
      name: 'Datum',
      selector: row => format(parseISO(row.latestRecord.createdAt), 'dd/MM/yyyy HH:mm'),
      sortable: true,
    },
    {
      cell: row => <AiFillEye onClick={() => navigate(`/communication/${row.latestRecord._id}`)} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const onRowClick = row => {
    navigate(`/communication/${row.latestRecord._id}`);
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, []);

  const [search, setSearch] = React.useState('');

  const handleSearch = event => {
    const term = event.target.value;
    setSearch(term);
    setFilteredRows(rows.filter(item => item.user.firstName.toLowerCase().includes(search.toLowerCase()) || item.user.lastName.toLowerCase().includes(search.toLowerCase())));
  };

  return (
    <React.Fragment>
      <Button style={{ float: 'right' }} bsSize="small" onClick={handleShow}>Toevoegen</Button>
      <AddCommunication show={show} onHide={handleClose} />
      <div style={{ clear: 'both' }}></div>
      <p className="mb-3 mt-3">Latest communication sent to every client.</p>
      <InputGroup className="mb-3 mt-3">
        <InputGroup.Text id="basic-addon1"><i className="nc-icon nc-zoom-split"></i></InputGroup.Text>
        <Form.Control
          placeholder="Zoek op naam"
          aria-label="Zoek op naam"
          aria-describedby="basic-addon1"
          onChange={handleSearch}
        />
      </InputGroup>
      <DataTable
        // title="Clients"
        theme="test"
        columns={columns}
        data={filteredRows}
        pagination
        progressPending={pending}
        onRowClicked={e => onRowClick(e)}
        progressComponent={<Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
      />
    </React.Fragment>
  );
};

export default Communication;
