/* eslint-disable */
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

import { fetchClients } from 'services/client.service';
import { postCommunication } from 'services/communication.service';

const AddCommunication = ({ show, onHide }) => {
  const [clients, setClients] = useState([]);
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  console.log(setErrors, values);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchClients();
      const data = await response.json();

      setClients(data);
    };

    fetchData().catch(console.error);
  }, []);

  const onSubmit = async () => {
    const response = await postCommunication(values);
    await response.json();

    onHide(true);
  };

  const onChange = () => {
    console.log('onChange');
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Communicatie</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={onSubmit} className="checkin-form">

          <Form.Group controlId="client">
            <Form.Label>Select Client</Form.Label>
            <Form.Control
              as="select"
              value={values.user}
              onChange={e => {
                console.log('e.target.value', e.target.value);
                setValues({
                  ...values,
                  ['user']: e.target.value,
                });
              }}
            >
            <option value={''}>Select a client</option>
            {clients.map((client, index) => (
              <option key={`client_${index}`} value={client['_id']}>{client.firstName} {client.lastName}</option>
            ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-4" controlId="name">
            <label>Title</label>
            <Form.Control
              placeholder="Titel"
              onChange={e => {
                console.log('e.target.value', e.target.value);
                setValues({
                  ...values,
                  ['name']: e.target.value,
                });
              }}
            />
            {errors.name && (
              <Form.Text className="text-danger">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-4" controlId="description">
            <label>Description</label>
            <Form.Control
              as="textarea"
              placeholder="Description"
              style={{ height: '100px' }}
              onChange={e => {
                console.log('e.target.value', e.target.value);
                setValues({
                  ...values,
                  ['description']: e.target.value,
                });
              }}
            />
            {errors.description && (
              <Form.Text className="text-danger">
                {errors.description.message}
              </Form.Text>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddCommunication;
