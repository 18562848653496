import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
} from 'react-bootstrap';

import { fetchDietVideo } from 'services/diet-videos.service';
import { getCurrentUser } from 'services/auth.service';

const DietVideoDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const [dietVideo, setDietVideo] = useState(null);

  useEffect(() => {
    const fetchSingleDietVideo = async () => {
      const response = await fetchDietVideo(slug);
      const result = await response.json();

      setDietVideo(result);
    };

    fetchSingleDietVideo().catch(console.error);
  }, []);

  if (!dietVideo) {
    return <Spinner />;
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="8">
            <Breadcrumb className="mt-3">
              <Breadcrumb.Item href={'/education/diet'}>
                Voeding
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{dietVideo.title}</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Card.Title as="h4">{dietVideo.title}</Card.Title>
                  {currentUser.roles.includes('ROLE_COACH') &&
                    <Button
                      style={{ backgroundColor: '#99fc86', border: '1px solid light-grey' }}
                      className="btn-fill"
                      bsStyle="primary"
                      bsSize="small"
                      onClick={() => navigate(`/education/diet/${slug}/edit`)}
                    >
                      Bewerken
                    </Button>
                  }
                </div>
                <div dangerouslySetInnerHTML={{ __html: dietVideo.embed }} />
                <Card.Text className="mt-4">
                  <div dangerouslySetInnerHTML={{ __html: dietVideo.description }} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DietVideoDetail;
