import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Breadcrumb,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { toast } from 'react-toastify';

import { addDietVideo, fetchDietVideoCategories } from 'services/diet-videos.service';

const AddDietVideo = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [title, setTitle] = useState('');
  const [embed, setEmbed] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    const fetchDietCategories = async () => {
      const response = await fetchDietVideoCategories();
      const data = await response.json();

      setCategories(data.map(singleCategory => ({
        value: singleCategory._id,
        label: singleCategory.label,
      })));
    };

    fetchDietCategories().catch(console.error);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    const response = await addDietVideo({
      title, description, embed, category,
    });
    const data = await response.json();

    toast.success(`Voeding video voor ${title} toegevoegd aan de videos.`);
    navigate(`/education/diet/${data.slug}`);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Breadcrumb className="mt-3">
              <Breadcrumb.Item href={'/education/diet'}>
                Voeding
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Nieuwe Video</Breadcrumb.Item>
            </Breadcrumb>
            <Card>
              <Card.Body>
                <Card.Title as="h4">Nieuwe Voeding video</Card.Title>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col className="pl-1" md="12">
                      <Form.Group>
                        <label htmlFor="name">
                          Naam
                        </label>
                        <Form.Control
                          onChange={e => setTitle(e.target.value)}
                          placeholder="Naam"
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <Form.Label>Omschrijving</Form.Label>
                        <CKEditor
                          editor={ClassicEditor}
                          data=""
                          onReady={editor => {
                            editor.editing.view.change(writer => {
                              writer.setStyle(
                                'height',
                                '200px',
                                editor.editing.view.document.getRoot(),
                              );
                            });
                          }}
                          onChange={((_, editor) => setDescription(editor.getData()))}
                          onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                          }}
                          onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="pr-1" md="12">
                      <Form.Group>
                        <Form.Label>Vimeo Embed</Form.Label>
                        <Form.Control
                          onChange={e => setEmbed(e.target.value)}
                          placeholder="Embed html"
                          rows={8}
                          style={{ height: '200px' }}
                          as="textarea"></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="4">
                      <Form.Group controlId="category">
                        <Form.Label>Categorie</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => setCategory(e.target.value)}
                          value={category}
                        >
                          <option value={''} disabled>Kies een categorie</option>
                          {categories.map(singleCategory => (
                            <option key={`category_${singleCategory.value}`} value={singleCategory.value}>{singleCategory.label}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    style={{ backgroundColor: '#99fc86', border: '1px solid light-grey' }}
                    className="mt-3 btn-fill"
                    bsStyle="primary"
                    bsSize="small"
                    type="submit">Toevoegen</Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddDietVideo;
