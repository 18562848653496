import { useEffect } from 'react';
import { Pagination as BootstrapPagination } from 'react-bootstrap';

import './Pagination.scss';

const Pagination = ({
  currentPage,
  totalPages,
  handlePageChange,
}) => {
  const maxDisplayedPages = 5; // Maximum number of pages to display before and after the current page
  const halfMaxDisplayedPages = Math.floor(maxDisplayedPages / 2);

  // Scroll to the top after each page change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - halfMaxDisplayedPages);
    const endPage = Math.min(totalPages, currentPage + halfMaxDisplayedPages);

    for (let page = startPage; page <= endPage; page++) {
      pageNumbers.push(
        <BootstrapPagination.Item
          key={page}
          active={page === currentPage}
          onClick={() => handlePageChange(page)}
        >
          {page}
        </BootstrapPagination.Item>,
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <BootstrapPagination.Ellipsis key="ellipsis-start" />,
      );
      pageNumbers.unshift(
        <BootstrapPagination.Item key={1} onClick={() => handlePageChange(1)}>
          {1}
        </BootstrapPagination.Item>,
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <BootstrapPagination.Ellipsis key="ellipsis-end" />,
      );
      pageNumbers.push(
        <BootstrapPagination.Item key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </BootstrapPagination.Item>,
      );
    }

    return pageNumbers;
  };

  return (
    <div className="superhuman-pagination" style={{ display: 'inline-flex', justifyContent: 'center', width: '100%' }}>
      <BootstrapPagination>
        {/* Previous page button */}
        <BootstrapPagination.Prev
          onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
          disabled={currentPage === 1}
        />

        {/* Render page numbers */}
        {renderPageNumbers()}

        {/* Next page button */}
        <BootstrapPagination.Next
          onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
          disabled={currentPage === totalPages}
        />
      </BootstrapPagination>
    </div>
  );
};

export default Pagination;
