import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Form,
  Container,
  Image,
  Row,
  Col,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';
import { FaFileWaveform } from 'react-icons/fa6';

import { fetchClient, deleteClient, updateClient } from 'services/client.service';
import { fetchIntakeForUser } from 'services/intake.service';
import { fetchCoaches } from 'services/coaches.service';
import { fetchCheckinsForUser } from 'services/checkin.service';
import DataCard from 'components/DataCard';

import AvatarImg from 'images/generic-avatar.svg';
import LarsImg from 'images/lars.png';

const ClientDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [pending, setPending] = useState(true);
  const [coaches, setCoaches] = useState([]);
  const [intake, setIntake] = useState(null);
  const [user, setUser] = useState({});
  const [email, setEmail] = useState('');
  const [cellphone, setCellPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [paymentRegularity, setPaymentRegularity] = useState('monthly');
  const [gender, setGender] = useState('male');
  const [coach, setCoach] = useState('');
  const [avatar, setAvatar] = useState(AvatarImg);
  const [excell, setExcell] = useState('');
  const [checkins, setCheckins] = useState([]);

  console.log(coach, avatar, pending);

  useEffect(() => {
    const fetchUserData = async () => {
      setPending(true);
      const response = await fetchClient(id);
      const data = await response.json();

      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmail(data.email);
      setCellPhone(data.cellphone);
      setPaymentRegularity(data.paymentRegularity);
      setCoach(data?.coach);
      setAvatar(data?.avatar);
      setGender(data?.gender);
      setExcell(data?.excell);
      setUser(data);
      setPending(false);
    };

    const fetchCoachesData = async () => {
      const response = await fetchCoaches();
      const data = await response.json();

      setCoaches(data.map(singleCoach => ({
        value: singleCoach._id,
        label: `${singleCoach.firstName} ${singleCoach.lastName}`,
      })));
    };

    const fetchIntakeData = async () => {
      const response = await fetchIntakeForUser(id);
      const data = await response.json();

      if (!data?.error) {
        setIntake(data);
      }
    };

    const fetchCheckins = async () => {
      const response = await fetchCheckinsForUser(id);
      const data = await response.json();

      setCheckins(data);
      setPending(false);
    };

    fetchUserData().catch(console.error);
    fetchCoachesData().catch(console.error);
    fetchIntakeData().catch(console.error);
    fetchCheckins().catch(console.error);
  }, []);

  const onDrop = file => {
    console.log('file', file);
  };

  const removeClient = async () => {
    const response = await deleteClient(user._id);
    await response.json();

    toast.success(`${user.firstName} ${user.lastName} removed from clients.`);
    navigate('/clients');
  };

  const onSubmit = async e => {
    e.preventDefault();
    const response = await updateClient(
      user._id,
      {
        firstName, lastName, cellphone, email, paymentRegularity, coach, excell, gender,
      },
    );
    const client = await response.json();

    toast.success(`Client ${client.firstName} ${client.lastName} successfully updated.`);
    navigate('/clients');
  };

  return (
    <>
      <Container fluid="true">
        <Row>
          <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Client {user.firstName} {user.lastName}</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col className="px-1" md="6">
                      <Form.Label>Avatar</Form.Label>
                      <Image width="150" src={user?.avatar || AvatarImg} />
                    </Col>
                    <Col className="px-1 mt-5" md="4">
                      <Form.Group controlId="avatar">
                        <Dropzone onDrop={onDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <section>
                              <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                Sleep foto hier neer of klik om foto te selecteren
                                <br />
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1" md="6">
                      <Form.Group className="mb-4" controlId="firstName">
                        <label htmlFor="firstName">Voornaam</label>
                        <Form.Control
                          type="text"
                          name="firstName"
                          placeholder=""
                          onChange={e => setFirstName(e.target.value)}
                          value={firstName}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="6">
                      <Form.Group className="mb-4" controlId="lastName">
                        <label htmlFor="lastName">Naam</label>
                        <Form.Control
                          type="text"
                          name="lastName"
                          onChange={e => setLastName(e.target.value)}
                          value={lastName}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1" md="6">
                      <Form.Group controlId="email">
                        <label htmlFor="email">E-mail</label>
                        <Form.Control
                          type="text"
                          onChange={e => setEmail(e.target.value)}
                          name="email"
                          placeholder=""
                          value={email}
                        />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-4" controlId="phone">
                        <label htmlFor="phone">Telefoon</label>
                        <Form.Control
                          type="text"
                          name="phone"
                          onChange={e => setCellPhone(e.target.value)}
                          value={cellphone}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1" md="6">
                      <Form.Group controlId="gender">
                        <Form.Label>Geslacht</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => setGender(e.target.value)}
                          value={gender}
                        >
                          <option value={'male'}>Man</option>
                          <option value={'female'}>Vrouw</option>
                          <option value={'other'}>Andere</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mb-4" controlId="excell">
                        <label htmlFor="phone">Excell Sheet</label>
                        <Form.Control
                          as="textarea"
                          name="excell"
                          style={{ height: '100px' }}
                          onChange={e => setExcell(e.target.value)}
                          value={excell}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1" md="6">
                      <Form.Group controlId="paymentRegularity">
                        <Form.Label>Betaling</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => setPaymentRegularity(e.target.value)}
                          value={paymentRegularity}
                        >
                          <option value={'monthly'}>Maandelijks</option>
                          <option value={'quarterly'}>Driemaandelijks</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col className="px-1" md="6">
                      <Form.Group controlId="paymentRegularity">
                        <Form.Label>Coach</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={e => setCoach(e.target.value)}
                          value={coach}
                        >
                          <option value={''} disabled>Kies een coach</option>
                          {coaches.map(singleCoach => (
                            <option key={`coach_${singleCoach.value}`} value={singleCoach.value}>{singleCoach.label}</option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="px-1 mt-4" md="12" style={{
                      display: 'flex',
                      justifyContent: 'flexStart',
                      gap: '1rem',
                    }}>
                      <Button
                        className="btn-fill pull-right"
                        type="submit"
                        variant="success">
                        Update Profile
                      </Button>

                      <Button
                        onClick={() => removeClient()}
                        className="btn-fill pull-right"
                        type="button"
                        variant="danger">
                        Delete Client
                      </Button>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-user">
              <Card.Body>
                <div style={{ textAlign: 'center' }}>
                  <h3>Coach</h3>
                  <img
                    alt="..."
                    className="avatar border-gray"
                    src={coach?.avatar || LarsImg}></img>
                  <h5 className="title">{coach?.firstName} {coach?.lastName}</h5>
                </div>
                <hr></hr>
                <p className="description text-center">
                  {intake && (
                    <><h3>Intake</h3><FaFileWaveform
                      size="40"
                      color="#99fc86"
                      title="Intake"
                      onClick={() => {
                        window.open(`/intake/${intake.user._id}`, '_blank');
                      }} /></>
                  )}
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <div className="card-body">
              <h3 className="card-title m-b-0 mb-2"><b>Checkins</b></h3>
            </div>
            {pending && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            {checkins.length === 0 && <p>Er zijn nog geen checkins</p>}
            <div className="latest-checkins">
              {checkins.map((checkin, index) => (
                <DataCard checkin={checkin} key={index} headerTitle={`${checkin.user.firstName} ${checkin.user.lastName}`} link={`/checkins/${checkin._id}`} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ClientDetail;
