import React, { useState } from 'react';
import {
  Tab,
  Tabs,
} from 'react-bootstrap';

import Communication from '../Communication/Communication';
import CheckinsCheckup from './CheckinsCheckup';

const Checkup = () => {
  const [activeType, setActiveType] = useState('checkins');

  return (
    <React.Fragment>
      <h3>Checkup ({activeType})</h3>
      <Tabs style={{ float: 'left' }} activeKey={activeType} onSelect={k => setActiveType(k)}>
        <Tab eventKey="checkins" title="Checkins" />
        <Tab eventKey="communication" title="Communication" />
      </Tabs>
      {activeType === 'communication' ? <Communication /> : <CheckinsCheckup />}
    </React.Fragment>
  );
};

export default Checkup;
