/* eslint-disable */
// import Dashboard from 'views/Dashboard.js';
import Education from 'components/Education';
import Training from 'components/Education/Training';
import TrainingDetail from 'components/Education/Training/Detail';
import Diet from 'components/Education/Diet';
import DietDetail from 'components/Education/Diet/Detail';
import CheckinDetail from 'components/CheckinDetail';
import Intake from 'components/Intake';
import TeamTraining from '../mutual/TeamTraining';

import Checkin from './Checkin';
import Checkins from './Checkins';

/* import TableList from 'views/TableList.js';
import Typography from 'views/Typography.js';
import Icons from 'views/Icons.js';
import Maps from 'views/Maps.js';
import Notifications from 'views/Notifications.js';
import Upgrade from 'views/Upgrade.js'; */

/*   {
    path: 'dashboard',
    name: 'Dashboard',
    icon: 'nc-icon nc-chart-pie-35',
    component: Dashboard,
    inNavbar: true,
    layout: '/dashboard',
  }, */

const dashboardRoutes = [
  {
    path: 'checkins',
    name: 'Checkins',
    icon: 'nc-icon nc-single-copy-04',
    component: Checkins,
    inNavbar: true,
    layout: '/admin',
  },
  {
    path: 'education',
    name: 'Educatie',
    icon: 'nc-icon nc-single-copy-04',
    component: Education,
    inNavbar: true,
    layout: '/admin',
  },
  {
    path: 'education/training',
    name: 'Training',
    icon: 'nc-icon nc-single-copy-04',
    component: Training,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/training/:slug',
    name: 'Training Detail',
    icon: 'nc-icon nc-single-copy-04',
    component: TrainingDetail,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/diet',
    name: 'Diet',
    icon: 'nc-icon nc-single-copy-04',
    component: Diet,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'education/diet/:slug',
    name: 'Diet Detail',
    icon: 'nc-icon nc-single-copy-04',
    component: DietDetail,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'checkins/:id',
    name: 'Checkin Detail',
    icon: 'nc-icon nc-single-copy-04',
    component: CheckinDetail,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'checkin',
    name: 'Checkin',
    icon: 'nc-icon nc-chart-pie-35',
    component: Checkin,
    inNavbar: false,
    layout: '/dashboard',
  },
  {
    path: 'intake',
    name: 'Intake',
    component: Intake,
    inNavbar: false,
    layout: '/dashboard',
  },
  {
    path: 'intake/:id',
    name: 'Intake',
    component: Intake,
    inNavbar: false,
    layout: '/admin',
  },
  {
    path: 'team-training',
    name: 'Team training',
    component: TeamTraining,
    inNavbar: true,
    layout: '/admin',
  },
  /* {
    path: 'table',
    name: 'Table List',
    icon: 'nc-icon nc-notes',
    component: TableList,
    layout: '/admin',
  },
  {
    path: 'typography',
    name: 'Typography',
    icon: 'nc-icon nc-paper-2',
    component: Typography,
    layout: '/admin',
  },
  {
    path: 'icons',
    name: 'Icons',
    icon: 'nc-icon nc-atom',
    component: Icons,
    layout: '/admin',
  },
  {
    path: 'maps',
    name: 'Maps',
    icon: 'nc-icon nc-pin-3',
    component: Maps,
    layout: '/admin',
  },
  {
    path: 'notifications',
    name: 'Notifications',
    icon: 'nc-icon nc-bell-55',
    component: Notifications,
    layout: '/admin',
  }, */
];

export default dashboardRoutes;
