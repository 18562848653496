/* eslint-disable */
import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  Container,
} from 'react-bootstrap';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Footer from 'components/Footer/Footer';

function Layout({
  routes,
  children
}) {
  const location = useLocation();
  const mainPanel = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      const element = document.getElementById('bodyClick');
      element.parentNode.removeChild(element);
    }
  }, [location]);

  return (
    <>
      <div className="wrapper">
        <AdminNavbar routes={routes} />
        <div className="main" ref={mainPanel}>
          <Container className="pt-3">
            <div className="content">
              {children}
            </div>
            <Footer />
          </Container>
        </div>
      </div>
    </>
  );
}

export default Layout;
