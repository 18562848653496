import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const submitTrainingScheme = (id, pdf) => {
  const formData = new FormData();
  formData.append('file', pdf);

  return fetch(`${API_URL}checkins/${id}/training-scheme`, {
    headers: { ...authHeader() },
    method: 'post',
    body: formData,
  });
};

export {
  submitTrainingScheme,
};
