import { useState } from 'react';
import Dropzone from 'react-dropzone';
import Image from 'react-bootstrap/Image';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { uploadCheckinImages } from 'services/upload.service';

import './ImageUpload.scss';

const CheckinImageUpload = ({
  setSelectedImages,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState([]);
  const [fileInfos, setFileInfos] = useState([]);

  const upload = file => {
    setProgress(0);
    return uploadCheckinImages(file, event => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then(({ data }) => {
        if (data?.secure_url) {
          const nextMessage = [
            ...message,
            `Volgende foto successvol upgeload: ${file.name}`,
          ];
          setMessage(nextMessage);
          setSelectedFiles([...selectedFiles, data]);
        } else {
          const nextMessage = [
            ...message,
            `Kon volgende foto niet uploaden: ${file.name}`,
          ];
          setMessage(nextMessage);
        }

        setProgress(0);
        return data;
      })
      .catch(() => {
        setProgress(0);
        const nextMessage = [
          ...message,
          `Kon volgende foto niet uploaden: ${file.name}`,
        ];

        setMessage(nextMessage);

        return {
          message: nextMessage,
        };
      });
  };

  const onDrop = file => {
    setProgress(0);
    setMessage([]);

    if (Array.isArray(file)) {
      Promise.all(file.map(singleFile => upload(singleFile)))
        .then(assets => {
          const copyFileInfos = fileInfos;
          const copySelectedFiles = selectedFiles;
          assets.forEach(asset => {
            if (asset?.secure_url) {
              copyFileInfos.push(asset);
              copySelectedFiles.push(asset);
            }
          });

          setFileInfos(copyFileInfos);
          setSelectedFiles(copySelectedFiles);
          setSelectedImages(copySelectedFiles);
        });
    } else {
      upload(file);
    }
  };

  return (
    <div>
      {progress > 0 &&
        <div className="mb-2">
          <ProgressBar animated now={progress} />
        </div>
      }

      <div className="my-3">
        <Dropzone onDrop={onDrop} multiple>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                Sleep bestanden hier neer of klik om bestanden te selecteren
                <br/>
                <b>{`${selectedFiles.length} progress fotos opgeladen`}</b>
              </div>
            </section>
          )}
        </Dropzone>
      </div>

      {(message && message.length > 0) && (
        <div className="alert alert-secondary" role="alert">
          <ul>
            {message.map((item, i) => <li key={i}>{item}</li>)}
          </ul>
        </div>
      )}

      {selectedFiles.length > 0 && (
        <div className="card">
          <div className="card-header">Lijst van progress fotos: </div>
          <div className="thumbnails-container">
            {selectedFiles &&
              selectedFiles.map((file, index) => (
                <div className="thumbnail" key={index} style={{ float: 'left', marginRight: '10px' }}>
                  <Image width="150" src={file.secure_url} alt={file.original_filename} />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckinImageUpload;
