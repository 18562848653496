import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const fetchTrainingVideoCategories = () => fetch(`${API_URL}training-videos/categories`, { headers: authHeader() });
const fetchTrainingVideos = () => fetch(`${API_URL}training-videos`, { headers: authHeader() });
const fetchTrainingVideo = id => fetch(`${API_URL}training-videos/${id}`, { headers: authHeader() });

const updateTrainingVideo = categories => fetch(`${API_URL}training-videos/categories`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'put',
  body: JSON.stringify(categories),
});

const addTrainingVideo = video => fetch(`${API_URL}training-videos`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(video),
});

const editTrainingVideo = (id, video) => fetch(`${API_URL}training-videos/${id}`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'put',
  body: JSON.stringify(video),
});

export {
  fetchTrainingVideoCategories,
  fetchTrainingVideos,
  fetchTrainingVideo,
  addTrainingVideo,
  editTrainingVideo,
  updateTrainingVideo,
};
