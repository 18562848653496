import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { getWeek, parseISO, format } from 'date-fns';

import { fetchSingleCommunication } from 'services/communication.service';
import Breadcrumbs from 'components/Breadcrumbs';

const CommunicationDetail = () => {
  const { id } = useParams();
  const [pending, setPending] = useState(false);
  const [communication, setCommunication] = useState(undefined);

  useEffect(() => {
    setPending(true);

    const fetchData = async () => {
      setPending(true);
      const response = await fetchSingleCommunication(id);
      const data = await response.json();

      setCommunication(data);
      setPending(false);
    };

    fetchData().catch(console.error);
  }, []);

  if (!communication || pending) {
    return <h1>Loading...</h1>;
  }

  return (
    <Container fluid>
      <Row>
        <Breadcrumbs breadcrumbs={[{ label: 'communication', path: '/communication', active: false }, { label: id, path: `/communication/${id}`, active: true }]} />
        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h3">
                <b>Communication week {getWeek(new Date(communication.createdAt))} van {communication.user.firstName} {communication.user.lastName}</b>
                <div className="float-end">
                  <Badge bg="secondary">{communication.status}</Badge>
                </div>
                <br />
              </Card.Title>
              {format(parseISO(communication.createdAt), 'dd/MM/yyyy HH:mm')}
            </Card.Header>
            <Card.Body>
              <Form className="checkin-form">
                <Form.Group className="mb-4" controlId="weight">
                  <label htmlFor="weight">Subject</label>
                  <Form.Control
                    value={communication.name}
                    disabled
                    type="text"
                    placeholder="Subject"
                    name="subject"
                  />
                </Form.Group>

                <Form.Group className="mb-4" controlId="weight">
                  <label htmlFor="weight">Description</label>
                  <Form.Control
                    value={communication.description}
                    disabled
                    type="text"
                    as="textarea"
                    style={{ height: '100px' }}
                    placeholder="Subject"
                    name="subject"
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CommunicationDetail;
