export const renderRating = rating => ({
  1: 'Zeer slecht',
  2: 'Slecht',
  3: 'Matig',
  4: 'Goed',
  5: 'Enorm goed',
})[rating] ?? '';

export const renderRelaxedRating = rating => ({
  1: 'Heel Relaxed',
  2: 'Relaxed',
  3: 'Neutraal',
  4: 'Stress',
  5: 'Veel stress',
})[rating] ?? '';

export const renderHungryRating = rating => ({
  1: 'Enorme honger',
  2: 'Honger',
  3: 'Neutraal',
  4: 'Lichte honger',
  5: 'Geen honger, voldaan bij elke maaltijd',
})[rating] ?? '';

export const renderEnergyLevel = energyLevel => ({
  1: 'Totaal geen energie',
  2: 'Slecht',
  3: 'Matig',
  4: 'Goed',
  5: 'Zeer energiek',
})[energyLevel] ?? '';
