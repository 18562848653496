import { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
} from 'react-bootstrap';
import { FaFileWaveform } from 'react-icons/fa6';

import { fetchOpenCheckinsCount } from 'services/checkin.service';

const CheckinsCount = () => {
  const [checkins, setCheckins] = useState(0);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const response = await fetchOpenCheckinsCount();
      const data = await response.json();

      setCheckins(data.count);
      setPending(false);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Card className="card-stats">
      <Card.Body>
        <Row>
          <Col xs="5">
            <div className="icon-big text-center">
              <FaFileWaveform size={70} color="#99fc86" />
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <Card.Title as="h4">Open Checkins</Card.Title>
              <Card.Title as="h4">
                {pending && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                {!pending && <b>{checkins}</b>}
              </Card.Title>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CheckinsCount;
