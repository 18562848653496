import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from 'react-data-table-component';
import Badge from 'react-bootstrap/Badge';
import { PiMicrosoftExcelLogoThin } from 'react-icons/pi';

import {
  InputGroup,
  Form,
  Button,
} from 'react-bootstrap';

import { fetchClients } from 'services/client.service';

const columns = [
  {
    name: 'Lastname',
    selector: row => row.lastName,
    sortable: true,
  },
  {
    name: 'Firstname',
    selector: row => row.firstName,
    sortable: true,
  },
  {
    name: 'Email',
    selector: row => row.email,
    sortable: true,
  },
  {
    name: 'Payments',
    selector: row => row.paymentRegularity,
    sortable: true,
  },
  {
    name: 'Status',
    selector: row => <Badge bg={row.status === 'active' ? 'success' : 'danger'}>{row.status}</Badge>,
    sortable: true,
  },
  {
    cell: row => (
      <div>
        {row?.excell &&
          <PiMicrosoftExcelLogoThin
            size="2em"
            onClick={() => {
              window.open(row?.excell, '_blank');
            }} />
        }
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
  },
];

const Clients = () => {
  const navigate = useNavigate();

  const [pending, setPending] = useState(true);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const response = await fetchClients();
      const data = await response.json();

      setFilteredRows(data);
      setRows(data);
      setPending(false);
    };

    fetchData().catch(console.error);
  }, []);

  const handleSearch = event => {
    const term = event.target.value;
    setFilteredRows(
      rows.filter(item => (`${item.firstName} ${item.lastName}`).toLowerCase().includes(term.toLowerCase())),
    );
  };

  const goToNewClient = () => {
    navigate('/clients/add');
  };

  return (
    <React.Fragment>
      <h3 style={{ float: 'left' }} >Clients</h3>
      <Button style={{ float: 'right', backgroundColor: '#99fc86', border: '1px solid light-grey' }} className="mt-2 btn-fill" bsStyle="primary" bsSize="small" onClick={goToNewClient}>Add Client</Button>
      <InputGroup className="mb-3 mt-3">
        <InputGroup.Text id="basic-addon1"><i className="nc-icon nc-zoom-split"></i></InputGroup.Text>
        <Form.Control
          placeholder="Voor-of achternaam"
          aria-label="name"
          aria-describedby="basic-addon1"
          onChange={handleSearch}
        />
      </InputGroup>
      <DataTable
        columns={columns}
        data={filteredRows}
        selectableRows
        pagination
        onRowClicked={row => navigate(`/clients/${row._id}`)}
        progressPending={pending}
        progressComponent={<Spinner animation="border" role="status"><span className="visually-hidden">Loading...</span></Spinner>}
      />
    </React.Fragment>
  );
};

export default Clients;
