const STATUSES = {
  open: 'open',
  reviewed: 'Bekeken',
};

const getStatus = status => STATUSES[status] || 'open';

export {
  getStatus,
};
