/* eslint-disable */
import React, { useState } from 'react';
import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import {
  Navbar, Container, Nav,
} from 'react-bootstrap';

import { logout } from 'services/auth.service';
import Logo from 'images/logo-initials.png';

import './AdminNavbar.scss';

function Header({
  routes,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [active, setActive] = useState('default');

  const mobileSidebarToggle = e => {
    e.preventDefault();
    document.documentElement.classList.toggle('nav-open');
    const node = document.createElement('div');
    node.id = 'bodyClick';
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle('nav-open');
    };
    document.body.appendChild(node);
  };

  const onLogout = () => {
    logout();
  }

  return (
      <Navbar fluid="true" bg="light" variant="dark" expand="lg">
        <Container>
        <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
          <Navbar.Brand
            href="/"
            className="mr-2">
            <img src={Logo} width="50" alt="logo superhuman coaching" />
          </Navbar.Brand>
        </div>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
          <span className="navbar-toggler-bar burger-lines"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav activeKey={location.pathname} className="nav me-auto" navbar>
            {routes.filter(route => route.inNavbar).map(({ path, name, icon }, key) => <Nav.Item>
              <Nav.Link
                key={`nav_link_${key}`}
                as={NavLink}
                to={path}
                onClick={() => navigate(path)}
                className="m-0">
                {/* <i className={icon}></i> */}
                <span className="dd-lg-block">{name}</span>
              </Nav.Link>
            </Nav.Item>
            )}
          </Nav>
          <Nav className="ml-auto" navbar>
          {/*
            <Nav.Item>
              <Nav.Link
                className="m-0"
                onClick={() => navigate('profile')}>
                <span className="no-icon">Profiel</span>
              </Nav.Link>
             </Nav.Item>
             */}
            {/*    <Dropdown as={Nav.Item}>
              <Dropdown.Toggle
                as={Nav.Link}
                data-toggle="dropdown"
                id="dropdown-67443507"
                variant="default"
                className="m-0">
                <i className="nc-icon nc-planet"></i>
                <span className="notification">5</span>
                <span className="d-lg-none ml-1">Notification</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  href="#pablo"
                  onClick={e => e.preventDefault()}>
                  Notification 1
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={e => e.preventDefault()}>
                  Notification 2
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={e => e.preventDefault()}>
                  Notification 3
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={e => e.preventDefault()}>
                  Notification 4
                </Dropdown.Item>
                <Dropdown.Item
                  href="#pablo"
                  onClick={e => e.preventDefault()}>
                  Another notification
                </Dropdown.Item>
              </Dropdown.Menu>
</Dropdown> */}
            <Nav.Item>
              <Nav.Link
                className="m-0"
                href="#pablo"
                onClick={() => onLogout()}>
                <span className="no-icon">Log out</span>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
  );
}

export default Header;
