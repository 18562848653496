import authHeader from './auth-header';
import ErrorHandler from './errorhandler';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const addIntake = values => fetch(`${API_URL}intake`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(values),
}).then(ErrorHandler)
  .then(res => {
    if (res.ok) return res.json();
  });

const fetchIntakeForUser = id => fetch(`${API_URL}intake/client/${id}`, {
  headers: authHeader(),
});

export {
  addIntake,
  fetchIntakeForUser,
};
