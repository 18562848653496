import authHeader from './auth-header';
import ErrorHandler from './errorhandler';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const subscribeTeamTraining = (id, body) => fetch(`${API_URL}team-training/${id}/subscribe`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(body),
}).then(ErrorHandler)
  .then(res => {
    if (res.ok) return res.json();
  });

const fetchTeamTrainingSlots = () => fetch(`${API_URL}team-training`, {
  headers: authHeader(),
});

export {
  fetchTeamTrainingSlots,
  subscribeTeamTraining,
};
