import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const fetchClients = () => fetch(`${API_URL}clients`, { headers: authHeader() });

const fetchClient = id => fetch(`${API_URL}clients/${id}`, { headers: authHeader() });

const deleteClient = id => fetch(`${API_URL}clients/${id}`, { method: 'DELETE', headers: authHeader() });

const updateClient = (id, data) => fetch(`${API_URL}clients/${id}`, { method: 'PUT', headers: { ...authHeader(), 'Content-Type': 'application/json' }, body: JSON.stringify(data) });

const fetchActiveClientsCount = () => fetch(`${API_URL}clients/active/count`, { headers: authHeader() });

const addClient = client => fetch(`${API_URL}clients`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(client),
});

export {
  addClient,
  deleteClient,
  updateClient,
  fetchClients,
  fetchClient,
  fetchActiveClientsCount,
};
