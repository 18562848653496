/* eslint-disable */
import { React, useState } from 'react';
import {
  Button,
  Container,
  Carousel,
  Form,
  Row,
  Col,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import logo from 'images/logo-text.png';

import { forgotPassword } from 'services/user.service';

import 'containers/login/Login.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const email = data.get('email');

    const res = await forgotPassword(email);

    if (!res.data.success) {
      setMessage(res.data.message);

      toast.error(res.data.message, {
        autoClose: 5000,
        position: 'top-right',
      });
    } else {
      toast.success(res.data.message, {
        autoClose: 5000,
        position: 'top-right',
      });
      setSuccess(true);
    }
  };

  if (success) {
    return (
      <section className="login-block">
        <Container>
          <Row>
            <Col md="4" className="login-sec">
              <Row className="text-center">
                <img src={logo} alt="Logo" className="text-center" />
              </Row>
              <h3>Wachtwoord reset mail werd verzonden.</h3>
              <div className="copy-text">SuperHuman Coaching <i className="fa fa-heart"></i></div>
            </Col>
            <Col md="8" className="banner-sec">
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/axjhwjg0n6tbax4wycbw"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/klsvmcirc7qruslkzf21"
                    alt="Second slide"
                  />

                  <Carousel.Caption>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/enndhhz82errxvtvpnap"
                    alt="Third slide"
                  />

                  <Carousel.Caption>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }

  return (
    <section className="login-block">
      <Container>
        <Row>
          <Col md="4" className="login-sec">
            <Row className="text-center">
              <img src={logo} alt="Logo" className="text-center" />
            </Row>
            <h3>Wachtwoord vergeten</h3>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4" controlId="lastName">
                <label htmlFor="lastName">Email</label>
                <Form.Control
                  type="text"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
              </Form.Group>
              <Button
                type="submit"
                fullWidth
                className="btn btn-login"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Wachtwoord resetten
              </Button>
              {message && (
                <div className="form-group mt-2">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}

            </Form>
            <div className="copy-text">SuperHuman Coaching <i className="fa fa-heart"></i></div>
          </Col>
          <Col md="8" className="banner-sec">
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/axjhwjg0n6tbax4wycbw"
                  alt="First slide"
                />
                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/klsvmcirc7qruslkzf21"
                  alt="Second slide"
                />

                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto/v1/superhuman-coaching/enndhhz82errxvtvpnap"
                  alt="Third slide"
                />

                <Carousel.Caption>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ForgotPassword
