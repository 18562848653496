import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const fetchDietVideoCategories = () => fetch(`${API_URL}diet-videos/categories`, { headers: authHeader() });
const fetchDietVideos = () => fetch(`${API_URL}diet-videos`, { headers: authHeader() });
const fetchDietVideo = id => fetch(`${API_URL}diet-videos/${id}`, { headers: authHeader() });

const updateDietVideo = categories => fetch(`${API_URL}diet-videos/categories`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'put',
  body: JSON.stringify(categories),
});

const addDietVideo = video => fetch(`${API_URL}diet-videos`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(video),
});

const editDietVideo = (id, video) => fetch(`${API_URL}diet-videos/${id}`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'put',
  body: JSON.stringify(video),
});

export {
  fetchDietVideoCategories,
  fetchDietVideos,
  fetchDietVideo,
  addDietVideo,
  editDietVideo,
  updateDietVideo,
};
