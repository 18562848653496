import authHeader from './auth-header';
import ErrorHandler from './errorhandler';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const postCheckin = values => fetch(`${API_URL}checkins`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'post',
  body: JSON.stringify(values),
}).then(ErrorHandler)
  .then(res => {
    if (res.ok) return res.json();
  });

const deleteCheckin = id => fetch(`${API_URL}checkins/${id}`, {
  headers: { ...authHeader() },
  method: 'delete',
}).then(ErrorHandler)
  .then(res => {
    if (res.ok) return res.json();
  });

const fetchCheckinsForCurrentUser = query => fetch(`${API_URL}checkins/me?page=${query.page}&limit=${query.limit}`, {
  headers: authHeader(),
});

const fetchCheckinsForUser = id => fetch(`${API_URL}checkins/client/${id}`, {
  headers: authHeader(),
});

const fetchCheckin = id => fetch(`${API_URL}checkins/${id}`, {
  headers: authHeader(),
});

const updateCheckinStatus = (id, status) => fetch(`${API_URL}checkins/${id}/status`, {
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
  method: 'put',
  body: JSON.stringify({
    status,
  }),
});

const fetchAllCheckins = () => fetch(`${API_URL}checkins`, {
  headers: authHeader(),
});

const fetchOpenCheckinsCount = () => fetch(`${API_URL}checkins/count/open`, {
  headers: authHeader(),
});

const fetchCheckinsByStatus = (status, query) => fetch(`${API_URL}checkins/status/${status}?page=${query.page}&limit=${query.limit}`, {
  headers: authHeader(),
});

const fetchLatestCheckinForAllUsers = () => fetch(`${API_URL}checkins/latest-all-users`, {
  headers: authHeader(),
});

export {
  postCheckin,
  deleteCheckin,
  fetchCheckinsForCurrentUser,
  fetchCheckinsForUser,
  fetchCheckin,
  updateCheckinStatus,
  fetchAllCheckins,
  fetchOpenCheckinsCount,
  fetchCheckinsByStatus,
  fetchLatestCheckinForAllUsers,
};
