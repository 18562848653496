import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://coaching.superhuman-coaching.com/api/' : 'http://localhost:2000/api/';

const uploadCheckinImages = (file, onUploadProgress) => {
  const formData = new FormData();

  if (Array.isArray(file)) {
    formData.append('file', file[0]);
  } else {
    formData.append('file', file);
  }

  return axios.post(`${API_URL}checkins/images`, formData, {
    headers: { ...authHeader() },
    onUploadProgress,
  });
};

export {
  uploadCheckinImages,
};
