import { useNavigate } from 'react-router-dom';

import logo from 'assets/img/logo-cropped.png';

import './Education.scss';

const Education = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="grid">
        <div className="row">
          <div className="col-md-4">
            <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
              <iframe
                src="https://player.vimeo.com/video/924097904?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                style={{
                  position: 'absolute', top: '0', left: '0', width: '100%', height: '100%',
                }}
                title="INTRO PLATFORM"></iframe>
            </div><script src="https://player.vimeo.com/api/player.js"></script>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <figure className="effect-ravi" onClick={() => navigate('/education/training')}>
              <img src="https://res.cloudinary.com/db9rdys0v/image/upload/t_Banner 16:9,f_auto,q_auto,o_60/v1696528189/superhuman-coaching/klsvmcirc7qruslkzf21.jpg" alt="training-thumb" />
              <figcaption>
                <h2>TRAINING</h2>
                <p>
                  <img src={logo} alt="Logo" width="100" className="logo" />
                </p>
              </figcaption>
            </figure>
          </div>
          <div className="col-md-4">
            <figure className="effect-ravi" onClick={() => navigate('/education/diet')}>
              <img src="https://res.cloudinary.com/db9rdys0v/image/upload/t_Banner 16:9,f_auto,q_auto,o_60/v1717142239/superhuman-coaching/diet_hnaplh.jpg" alt="diet-thumb" />
              <figcaption>
                <h2>VOEDING</h2>
                <p>
                  <img src={logo} alt="Logo" width="100" className="logo" />
                </p>
              </figcaption>
            </figure>
          </div>
          {/*
          <div className="col-md-4">
            <figure className="effect-ravi">
              <img src="https://res.cloudinary.com/db9rdys0v/image/upload/f_auto,q_auto,o_60/v1/superhuman-coaching/axjhwjg0n6tbax4wycbw" alt="img25" />
              <figcaption>
                <h2>VOEDING</h2>
                <p>
                  <img src={logo} alt="Logo" width="100" className="logo" />
                </p>
              </figcaption>
            </figure>
  </div> */}
          {/*  <div className="col">
            <figure className="effect-ravi">
              <img src="https://picsum.photos/1000/805/?random" alt="img25" />
              <figcaption>
                <h2>ANDERE</h2>
                <p>
                  <i className="fa fa-search"></i>

                </p>
              </figcaption>
            </figure>
</div> */}
        </div>

      </div>

    </div>
  );
};

export default Education;
