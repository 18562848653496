import { useEffect, useState } from 'react';
import {
  Card,
  Row,
  Col,
} from 'react-bootstrap';
import { GiStrongMan } from 'react-icons/gi';

import { fetchActiveClientsCount } from 'services/client.service';

const ClientsCount = () => {
  const [clients, setClients] = useState(0);
  const [pending, setPending] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setPending(true);
      const response = await fetchActiveClientsCount();
      const data = await response.json();

      setClients(data.count);
      setPending(false);
    };

    fetchData().catch(console.error);
  }, []);

  return (
    <Card className="card-stats">
      <Card.Body>
        <Row>
          <Col xs="5">
            <div className="icon-big text-center icon-warning">
             <GiStrongMan size={70} color="#99fc86" />
            </div>
          </Col>
          <Col xs="7">
            <div className="numbers">
              <Card.Title as="h4">Actieve Clients</Card.Title>
              <Card.Title as="h4">
                {pending && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                {!pending && <b>{clients}</b>}
              </Card.Title>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ClientsCount;
